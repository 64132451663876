import {createTheme} from '@mui/material';
import Logo from '../images/langing-logo.svg';

const common = '#444';
const white = '#FFF';
const dark = '#16163F';
const light = '#EFEFEF';
const lighter = '#FAFAFA';
const darker = '#999';
const disabled = '#D2D2D2';
const success = '#0ABAB5';
const secondary = '#004AAD';
const error = '#E42115';

const theme = createTheme({
  palette: {
    common: {
      black: common,
      white: white,
    },
    primary: {
      main: success,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      contrastText: 'white',
    },
    success: {
      main: success,
      contrastText: 'white',
    },
    error: {
      main: error,
    },
    info: {
      dark: darker,
      main: dark,
      light: light,
    },
    action: {
      hover: lighter,
      disabled: disabled,
    },
  },
  typography: {
    fontFamily: 'Quicksand, Helvetica, sans-serif',
    button: {
      fontFamily: 'Quicksand, Helvetica, sans-serif',
      fontSize: '1em',
      fontWeight: 600,
      textTransform: 'unset',
    },
    body1: {
      fontSize: '0.875em',
      fontWeight: '500',
    },
    body2: {
      color: darker,
      fontSize: '0.875em',
      lineHeight: '1.25em',
      fontWeight: '500',
    },
    h1: {
      background: `url(${Logo}) no-repeat center center/148px 40px`,
      height: '40px',
      width: '148px',
      textIndent: '-99999px',
    },
    h2: {
      fontSize: '2em',
      fontWeight: '600',
    },
    h3: {
      fontSize: '1.5em',
      fontWeight: '600',
      lineHeight: '1.25em',
    },
    h4: {
      fontSize: '1.25em',
      fontWeight: '600',
      lineHeight: '1.25em',
      color: dark,
    },
    h5: {
      fontSize: '1.125em',
      fontWeight: '600',
      lineHeight: '1.25em',
      color: dark,
    },
    h6: {
      color: '#666666',
      fontSize: '0.938em',
      fontWeight: '600',
      lineHeight: '1.25em',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: light,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: light,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.938em',
          '&.Mui-disabled': {
            backgroundColor: darker,
            color: 'rgba(255,255,255,.5)',
          },
          '&.MuiButton-contained': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          fontFamily: 'Quicksand, Helvetica, sans-serif',
          fontSize: '0.875em',
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: darker,
            borderWidth: '1px',
          },
          '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: error,
            borderWidth: '1px',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline legend': {
            fontSize: '1em',
            maxWidth: '100%',
          },
          '& input::placeholder': {
            color: disabled,
            opacity: 1,
          },
          '&.Mui-disabled': {
            backgroundColor: '#F1F1F1',
            borderColor: disabled,
            color: disabled,
            '& input': {
              WebkitTextFillColor: disabled,
            },
          },
        },
        input: {
          height: '1.644em',
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& legend': {
            marginLeft: '2px',
          },
          '& legend>span': {
            padding: '0 2px',
          },
          '& legend>span.notranslate': {
            padding: '0',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        required: false,
      },
      styleOverrides: {
        root: {
          fontFamily: 'Quicksand, Helvetica, sans-serif',
          fontSize: '1.125rem',
          lineHeight: '1.25em',
          top: '-4px',
          fontWeight: '500',
          '&.Mui-focused': {
            color: common,
            backgroundColor: white,
          },
          '&.Mui-error': {
            color: error,
          },
          '&.Mui-disabled': {
            color: darker,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            },
            '& .MuiButtonBase-root': {
              color: common,
            },
          },
        },
      },
      styleOverrides: {
        iconOutlined: {
          '&.MuiSelect-icon': {
            top: 'auto',
            right: 9,
            fill: common,
          },
          '&.MuiSelect-icon.Mui-disabled': {
            fill: disabled,
          },
        },
        select: {
          lineHeight: '1.644em',
          '& em': {
            fontStyle: 'normal',
            color: disabled,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: '2px',
          whiteSpace: 'nowrap',
          marginRight: '0',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardError': {
            backgroundColor: 'rgba(228, 33, 21, 0.15)',
            border: `${error} solid 1px`,
          },
          '&.MuiAlert-standardSuccess': {
            alignItems: 'center',
            backgroundColor: '#61AB03',
            '& .MuiAlert-message': {
              color: white,
            },
            '& .MuiAlert-action': {
              paddingTop: 0,
              paddingLeft: '8px',
            },
            '& .MuiButton-root': {
              transform: `scale(0.714)`,
              fontWeight: '500',
              color: white,
              textDecoration: 'underline',
            },
            '& .MuiAlert-icon': {
              marginRight: '8px',
            },
          },
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FDF6DC',
            border: `#F4C315 solid 1px`,
          },
          '&.MuiAlert-outlinedSuccess': {
            backgroundColor: '#E7F2D9',
            border: `#61AB03 solid 1px`,
          },
          '&.MuiAlert-outlinedError': {
            backgroundColor: 'rgba(228, 33, 21, 0.15)',
            border: `${error} solid 1px`,
          },
          '&.MuiAlert-outlinedWarning': {
            backgroundColor: '#FDF6DC',
            border: `#F4C315 solid 1px`,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        clearIcon: false,
      },
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: common,
          },
          '&.Mui-error': {
            color: error,
          },
          '&.MuiAutocomplete-hasPopupIcon .MuiTextField-root .MuiOutlinedInput-root':
            {
              paddingRight: '24px',
            },
          '& .MuiFormLabel-root.Mui-disabled': {
            color: darker,
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: 'auto',
            fill: common,
          },
        },
        popper: {
          whiteSpace: 'nowrap',
          fontSize: '1em',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          boxShadow: '0 2px 16px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          },
          '& .MuiDivider-root': {
            borderColor: light,
            margin: 0,
          },
          '& .MuiList-root': {
            padding: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875em',
          color: darker,
          padding: '12px 50px 8px 14px',
          lineHeight: '1.25em',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          color: darker,
          padding: '12px 24px',
          borderBottom: `${light} solid 1px`,
          '& .MuiLink-root': {
            fontSize: '0.875em',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              backgroundColor: light,
              fontSize: '0.938em',
              paddingTop: '1px',
              paddingBottom: '1px',
              color: darker,
              fontWeight: '600',
            },
            '& .MuiTableSortLabel-root': {
              color: darker,
              fontWeight: '600',
            },
          },
          '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
              fontSize: '1em',
              paddingTop: '4px',
              paddingBottom: '4px',
            },
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            '>.MuiTableCell-root': {
              backgroundColor: lighter,
            },
          },
          '& .MuiTableRow-root.Mui-selected': {
            '.MuiTableCell-root': {
              backgroundColor: '#E5EDF7',
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          padding: '25px 24px',
        },
        spacer: {
          flex: 0,
        },
        selectLabel: {
          color: darker,
          fontSize: '1em',
        },
        displayedRows: {
          display: 'none',
        },
        selectRoot: {
          border: `${disabled} solid 1px`,
          borderRadius: '4px',
          color: '#666',
          marginLeft: '26px',
          marginRight: '16px',
          '& .MuiSelect-select': {
            width: '36px',
            hiehgt: '40px',
            textAlign: 'left',
            textAlignLast: 'left',
            fontSize: '0.875em',
            lineHeight: '2em',
            padding: '10px 24px 7px 10px',
          },
        },
        selectIcon: {
          top: 'auto',
          right: 9,
          fill: '#999',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        label: {
          color: darker,
          fontSize: '0.75em',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiTableContainer-root': {
            borderRadius: '4px',
          },
          '& .MuiDialog-container .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              backgroundColor: lighter,
              borderBottom: '0',
              padding: '16px 24px 8px 24px',
              fontSize: '0.75em',
              fontWeight: '500',
              color: darker,
            },
          },
          '& .MuiDialog-container .MuiTableBody-root': {
            '& .MuiTableCell-root': {
              padding: '8px 24px 9px 24px',
              borderBottom: `${light} solid 1px`,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: common,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingRight: '6px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        modal: {
          '& .MuiPaper-root': {
            borderRadius: '4px 0 0 4px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: 'transparent solid 1px',
          margin: '0 8px',
          fontSize: '1.125em',
          fontWeight: 600,
          color: success,
          '&.Mui-selected': {
            borderColor: '#ccc',
            borderBottomWidth: 0,
            color: dark,
            backgroundColor: white,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
          top: '1px',
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          objectFit: 'contain',
        },
      },
    },
  },
});

export default theme;
