import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { finalize, mergeMap, Observable, of, Subject, tap } from 'rxjs';
import { axiosInstance } from '../../api/fetchData';
import { USER_PROFILE, USER_SELECTED_DEPT_ID, USER_SELECTED_ORG_ID } from '../../constants/storage';
import { useAppDispatch, useAppSelector } from '../../hook/useRedux';
import {
  OrganizationDetails,
  OrgUser,
  UpdateOnboardingProfileRequest,
} from '../../interface';
import {
  setIsLoadingDetails,
  setOrganization,
} from '../../redux/features/organizationSlice';
import {
  selectIsLoadingOrganizationDetails,
  selectOrganizationDetails,
} from '../../redux/selectors/organizationSlice';
import { getOrganizationDetails, updateOrgProfile } from '../../services';
import { getObject, getString } from '../../utils/storageUtils';

interface State {
  orgDetails: OrganizationDetails | undefined;
  setOrgDetails: (val: OrganizationDetails) => void;
  pullOrgDetails: (orgId: string) => Observable<OrganizationDetails>;
  pushOrgDetails: (
    data: UpdateOnboardingProfileRequest
  ) => Observable<OrganizationDetails>;
  isPulling: boolean;
}

const initialState: State = {
  orgDetails: undefined,
  setOrgDetails: () => {
    throw new Error('setOrgDetails Stub');
  },
  pullOrgDetails: () => {
    throw new Error('pullOrgDetails Stub');
  },
  pushOrgDetails: () => {
    throw new Error('pushOrgDetails Stub');
  },
  isPulling: false,
};

export const OrganizationDetailsContext = createContext(initialState);

function OrganizationDetailsProvider({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();

  const orgDetails = useAppSelector(selectOrganizationDetails);
  const subject = useRef(new Subject<OrganizationDetails>());
  const isLoading = useAppSelector(selectIsLoadingOrganizationDetails);

  const orgDetailsRef = useRef<OrganizationDetails | undefined>(orgDetails);
  orgDetailsRef.current = orgDetails;
  const isLoadingRef = useRef<string | undefined>(orgDetails?.id);
  isLoadingRef.current = isLoading ? isLoadingRef.current : undefined;

  if (getObject<OrgUser>(USER_PROFILE, null)?.id) {
    axiosInstance.defaults.headers.common['invoked_by'] = getObject<OrgUser>(
      USER_PROFILE,
      null,
    )?.id;
  }
  if (getString(USER_SELECTED_ORG_ID, '')) {
    axiosInstance.defaults.headers.common['org_id'] = getString(
      USER_SELECTED_ORG_ID,
      '',
    );
  }
  if (getString(USER_SELECTED_DEPT_ID, '')) {
    axiosInstance.defaults.headers.common['dept_id'] = getString(
      USER_SELECTED_DEPT_ID,
      '',
    );
  }

  useEffect(() => {
    if (orgDetails) {
      subject.current.next(orgDetails);
    }
  }, [orgDetails]);

  const setOrgDetails = useCallback(
    (val: OrganizationDetails) => {
      dispatch(
        setOrganization({
          org: val,
        })
      );
    },
    [dispatch]
  );

  const pullOrgDetails = useCallback(
    (orgId: string) => {
      if (orgDetailsRef.current && orgDetailsRef.current.id === orgId) {
        return of(orgDetailsRef.current);
      } else if (isLoadingRef.current && isLoadingRef.current === orgId) {
        return subject.current.asObservable();
      } else {
        return of(0).pipe(
          tap(() => {
            dispatch(
              setIsLoadingDetails({
                loading: true,
              })
            );
          }),
          mergeMap(() =>
            getOrganizationDetails().pipe(
              tap((res) => {
                orgDetailsRef.current = res;
                setOrgDetails(res);
              })
            )
          ),
          finalize(() => {
            dispatch(
              setIsLoadingDetails({
                loading: false,
              })
            );
          })
        );
      }
    },
    [orgDetailsRef, isLoadingRef, subject]
  );

  const pushOrgDetails = useCallback((data: UpdateOnboardingProfileRequest) => {
    return updateOrgProfile(data).pipe(
      tap((res) => {
        setOrgDetails(res);
      })
    );
  }, []);

  return (
    <OrganizationDetailsContext.Provider
      value={{
        orgDetails,
        setOrgDetails,
        pullOrgDetails,
        pushOrgDetails,
        isPulling: isLoading,
      }}
    >
      {children}
    </OrganizationDetailsContext.Provider>
  );
}

export default OrganizationDetailsProvider;
