import {Observable} from 'rxjs';
import {
  AddUserToDepartmentRequest,
  BulkAddUserToDepartmentRequest,
  BulkAddUserToDepartmentResponse,
  BulkDeleteDepartmentRequest,
  BulkDeleteDepartmentResponse,
  CreateDepartmentRequest,
  DeleteDepartmentRequest,
  DeleteUsersFromDepartmentRequest,
  DepartmentDetails,
  DepartmentOverview,
  DepartmentPreview,
  GetDepartmentsRequest,
  GetDepartmentUsersRequest,
  GetDepartmentUsersResponse,
  GetRolesRequest,
  GetRolesResponse,
  OrganizationDetails,
  OrgUser,
  PaginationResponse,
  SearchDepartmentsRequest,
  UpdateDepartmentRequest,
} from '../interface';
import {axiosRequest} from './http';

export function getOrganizationDetails(): Observable<OrganizationDetails> {
  return axiosRequest({
    method: 'GET',
    url: `/organization/info`,
  }) as Observable<OrganizationDetails>;
}

export function getDepartmentCount(): Observable<number> {
  return axiosRequest({
    method: 'GET',
    url: '/department/count',
  }) as Observable<number>;
}

export function getDepartmentOverview(): Observable<DepartmentOverview> {
  return axiosRequest({
    method: 'GET',
    url: '/department/overview',
  }) as Observable<DepartmentOverview>;
}

export function searchDepartments(
  data: SearchDepartmentsRequest,
): Observable<PaginationResponse<DepartmentPreview>> {
  return axiosRequest({
    method: 'GET',
    url: '/department/search',
    params: data,
  }) as Observable<PaginationResponse<DepartmentPreview>>;
}

export function getDepartments(
  data: GetDepartmentsRequest,
): Observable<PaginationResponse<DepartmentPreview>> {
  return axiosRequest({
    method: 'GET',
    url: '/department/',
    params: data,
  }) as Observable<PaginationResponse<DepartmentPreview>>;
}

export function createDepartment(
  data: CreateDepartmentRequest,
): Observable<DepartmentDetails> {
  return axiosRequest({
    method: 'POST',
    url: '/department',
    data,
  }) as Observable<DepartmentDetails>;
}

export function updateDepartment(
  data: UpdateDepartmentRequest,
): Observable<DepartmentDetails> {
  const {dept_id, ...rest} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/department/${dept_id}`,
    data: rest,
  }) as Observable<DepartmentDetails>;
}

export function deleteDepartment(
  data: DeleteDepartmentRequest,
): Observable<DepartmentDetails> {
  const {dept_id} = data;
  return axiosRequest({
    method: 'DELETE',
    url: `/department/${dept_id}`,
  }) as Observable<DepartmentDetails>;
}

export function bulkDeleteDeparment(
  data: BulkDeleteDepartmentRequest,
): Observable<BulkDeleteDepartmentResponse> {
  return axiosRequest({
    method: 'DELETE',
    url: `/department/bulk`,
    data,
  }) as Observable<BulkDeleteDepartmentResponse>;
}

export function getDepartmentDetails(data: {
  dept_id: string;
}): Observable<DepartmentDetails> {
  return axiosRequest({
    method: 'GET',
    url: `/department/${data.dept_id}`,
  }) as Observable<DepartmentDetails>;
}

export function getDepartmentUsers(
  data: GetDepartmentUsersRequest,
): Observable<GetDepartmentUsersResponse> {
  const {id, ...queryParams} = data;
  return axiosRequest({
    method: 'GET',
    url: `/department/user/${id}`,
    params: queryParams,
  }) as Observable<GetDepartmentUsersResponse>;
}

export function addUserToDepartment(
  data: AddUserToDepartmentRequest,
): Observable<OrgUser> {
  return axiosRequest({
    method: 'PATCH',
    url: '/department/user',
    data,
  }) as Observable<OrgUser>;
}

export function bulkAddUsersToDeparment(
  data: BulkAddUserToDepartmentRequest,
): Observable<BulkAddUserToDepartmentResponse> {
  return axiosRequest({
    method: 'PATCH',
    url: `/department/bulk/user`,
    data,
  }) as Observable<BulkAddUserToDepartmentResponse>;
}

export function getPlatformRoles(
  data?: GetRolesRequest,
): Observable<GetRolesResponse> {
  return axiosRequest({
    method: 'GET',
    url: '/role',
    params: data,
  }) as Observable<GetRolesResponse>;
}

export function getOrgUserRoles(
  data?: GetRolesRequest,
): Observable<GetRolesResponse> {
  return axiosRequest({
    method: 'GET',
    url: '/organization/role',
    params: data,
  }) as Observable<GetRolesResponse>;
}

export function deleteUserFromDepartment(data: {
  user_id: string;
  dept_id: string;
}): Observable<OrgUser> {
  const {user_id, dept_id} = data;
  if (dept_id) {
    return axiosRequest({
      method: 'PATCH',
      url: `department/user/${user_id}`,
      headers: {
        dept_id: dept_id,
      },
    }) as Observable<OrgUser>;
  } else {
    return axiosRequest({
      method: 'PATCH',
      url: `department/user/${user_id}`,
    }) as Observable<OrgUser>;
  }
}

export function deleteUsersFromDepartment(
  data: DeleteUsersFromDepartmentRequest,
): Observable<OrgUser> {
  return axiosRequest({
    method: 'DELETE',
    url: `/department/bulk/user`,
    data: data,
  }) as Observable<OrgUser>;
}

export function enableDepartment(data: {
  dept_id: string;
}): Observable<DepartmentDetails> {
  const {dept_id} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/department/enable/${dept_id}`,
  }) as Observable<DepartmentDetails>;
}
