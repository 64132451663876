import moment from 'moment';

function isExpiresd(value?: string | null) {
  if (!value) {
    return false;
  }
  if (moment.utc(value).isAfter(moment.utc())) {
    return false;
  }
  return true;
}

export default isExpiresd;
