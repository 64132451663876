import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Functionalities} from '../../interface';

// Define a type for the slice state
interface FunctionalitiesState {
  // better use this as lookup with LookupContext, than use redux selector
  functionalities: Functionalities[];
}

// Define the initial state using that type
const initialState: FunctionalitiesState = {
  functionalities: [],
};

export const functionalitiesSlice = createSlice({
  name: 'Functionalities',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearFunctionalitiesSlice() {
      return initialState;
    },
    setFunctionalities(
      state,
      action: PayloadAction<{functionalities: Functionalities[]}>,
    ) {
      const {functionalities} = action.payload;
      state.functionalities = functionalities;
    },
  },
});

export const {clearFunctionalitiesSlice, setFunctionalities} =
  functionalitiesSlice.actions;

export default functionalitiesSlice.reducer;
