import {configureStore} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from './features/authSlice';
import snackbarReducer from './features/snackbarSlice';
import meunReducer from './features/menuSlice';
import organizationReducer from './features/organizationSlice';
import platformReducer from './features/platformSlice';
import functionalitiesReducer from './features/functionalitiesSlice';
import currentOrganizationSliceReducer from './features/currentOrgSlice';
import reportsDateRangeSliceReducer from './features/reportsDateRangeSlice';
import selectedReportOrgSliceReducer from './features/selectedReportOrgSlice';
import notificationsSliceReducer from './features/notificationsSlice';
import runOnSliceReducer from './features/runOnSlice';
import permissionSliceReducer from './features/permissionSlice';

const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware();
    if (process.env.NODE_ENV === 'development') {
      middlewares.push(logger);
    }
    return middlewares;
  },
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    menu: meunReducer,
    organization: organizationReducer,
    platform: platformReducer,
    functionalities: functionalitiesReducer,
    currentOrganization: currentOrganizationSliceReducer,
    reportsDateRange: reportsDateRangeSliceReducer,
    selectedReportOrg: selectedReportOrgSliceReducer,
    notifications: notificationsSliceReducer,
    runOn: runOnSliceReducer,
    permissions: permissionSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
