import _ from "lodash";
import axios, { AxiosResponse } from "axios";
import { Observable } from "rxjs";
import { axiosRequest } from "./http";
import { axiosInstance } from "../api/fetchData";
import { GetOrgsRequest, GetOrgsResponse } from "../interface";
import { WITHCREDENTIALS } from "../constants";
import { getDateParams } from "../utils/dateUtils";

export async function getMultiTenantData({ start_time, end_time, sensitivity }): Promise<any> {
	try {
		const params = getDateParams({ from: start_time, to: end_time, dateText: null, sensitivity });
		const response = (
			await axiosInstance({
				baseURL: process.env.REACT_APP_API_BASE_URL,
				method: "GET",
				url: "/dashboard/data/orgs/drill-down",
				params: {
					sensitivity,
					...params
				}
			})
		).data.result;

		const filteredResponse = {
			credential_issued: response.credential_issued,
			credential_revoked: response.credential_revoked,
			credential_verified: response.credential_verified
		}

		const transformedResponse = {};

		transformedResponse["orgWise"] = filteredResponse;

		transformedResponse["all"] = {
			credential_issued: {},
			credential_verified: {},
			credential_revoked: {}
		};

		transformedResponse["counts"] = {
			credential_issued: 0,
			credential_verified: 0,
			credential_revoked: 0
		};

		transformedResponse["orgWiseCounts"] = {};

		Object.keys(filteredResponse).forEach((type) => {
			Object.keys(filteredResponse[type]).forEach((orgId) => {
				if (!transformedResponse["orgWiseCounts"][orgId]) {
					transformedResponse["orgWiseCounts"][orgId] = {
						credential_issued: 0,
						credential_verified: 0,
						credential_revoked: 0
					};
				}

				Object.keys(filteredResponse[type][orgId]).forEach((timestamp) => {
					transformedResponse["all"][type][timestamp] = transformedResponse["all"][type][timestamp]
						? transformedResponse["all"][type][timestamp] + filteredResponse[type][orgId][timestamp]
						: filteredResponse[type][orgId][timestamp];

					transformedResponse["counts"][type] =
						transformedResponse["counts"][type] + filteredResponse[type][orgId][timestamp];

					transformedResponse["orgWiseCounts"][orgId][type] =
						transformedResponse["orgWiseCounts"][orgId][type] + filteredResponse[type][orgId][timestamp];
				});
			});
			transformedResponse["all"][type] = Object.keys(transformedResponse["all"][type])
				.sort()
				.reduce((obj, key) => {
					obj[key] = transformedResponse["all"][type][key];
					return obj;
				}, {});
		});

		return transformedResponse;
	} catch (err) {
		return await Promise.reject(err);
	}
}
