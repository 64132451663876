import {AxiosResponse} from 'axios';
import {PermissionsResponse} from './../interface';
import _ from 'lodash';
export function flatPermissions(permissions: PermissionsResponse[][]) {
  const flat = _.flatMap(permissions);
  const result = _.reduce(
    flat,
    (result: PermissionsResponse[], permission) => {
      if (!result.length) {
        result = _.concat(result, permission);
      } else {
        const index = _.findIndex(result, (r) =>
          _.isEqual(r.functionality_name, permission.functionality_name),
        );
        if (!_.isEqual(index, -1)) {
          if (permission.read) {
            result[index].read = true;
          }
          if (permission.write) {
            result[index].write = true;
          }
        } else {
          result = _.concat(result, permission);
        }
      }
      return result;
    },
    [],
  );
  return result;
}

export async function asyncPromise(
  res: Promise<PromiseSettledResult<AxiosResponse>[]>,
) {
  const result = await res;
  return _.map(result, (r) => {
    if (r.status === 'rejected') {
      return [];
    } else {
      return r.value.data.content;
    }
  });
}
