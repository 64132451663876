import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PermissionsResponse} from '../../interface';

// Define a type for the slice state
interface FunctionalitiesState {
  // better use this as lookup with LookupContext, than use redux selector
  permissions?: PermissionsResponse[];
}

// Define the initial state using that type
const initialState: FunctionalitiesState = {
  permissions: undefined,
};

export const permissionSlice = createSlice({
  name: 'Permissions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearPermissionSlice() {
      return initialState;
    },
    setPermissions(
      state,
      action: PayloadAction<{permissions: PermissionsResponse[]}>,
    ) {
      const {permissions} = action.payload;
      state.permissions = permissions;
    },
  },
});

export const {clearPermissionSlice, setPermissions} = permissionSlice.actions;

export default permissionSlice.reducer;
