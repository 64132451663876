import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Notification} from '../../interface';
import _ from 'lodash';
import {
  clearNotificationsStorage,
  updateNotificationsStorage,
} from '../../utils/updateNotificationsStoage';
import filterNotifications from '../../utils/filterNotifications';

// Define a type for the slice state
interface NotificationsState {
  // better use this as lookup with LookupContext, than use redux selector
  notifications: Notification[];
}

// Define the initial state using that type
const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'Notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearNotifications() {
      clearNotificationsStorage();
      return initialState;
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = filterNotifications(action.payload);
      updateNotificationsStorage(JSON.stringify(state.notifications));
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = _.uniqBy(
        [
          {...action.payload, read_status: 'new'},
          ...state.notifications,
        ],
        'message_id',
      );
      updateNotificationsStorage(JSON.stringify(state.notifications));
    },
    removeNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = _.reject(state.notifications, [
        'message_id',
        action.payload.message_id,
      ]);
      updateNotificationsStorage(JSON.stringify(state.notifications));
    },
    readNotification: (state, action: PayloadAction<Notification>) => {
      const index = _.findIndex(state.notifications, [
        'message_id',
        action.payload.message_id,
      ]);
      state.notifications = [
        ..._.slice(state.notifications, 0, index),
        {...state.notifications[index], read_status: ''},
        ..._.slice(state.notifications, index + 1),
      ];
      updateNotificationsStorage(JSON.stringify(state.notifications));
    },
    readNotifications: (state) => {
      state.notifications = _.map(state.notifications, (n) => ({
        ...n,
        read_status: '',
      }));
      updateNotificationsStorage(JSON.stringify(state.notifications));
    },
    resetNotifications: (state) => {
      state.notifications = _.cloneDeep(state.notifications);
    },
  },
});

export const {
  clearNotifications,
  setNotifications,
  addNotification,
  removeNotification,
  readNotification,
  readNotifications,
  resetNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
