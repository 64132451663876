import { useCallback, useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';

const useSubscription = () => {
  const subscription = useRef<Subscription | null>(null);

  const unsubscribe = useCallback(() => {
    if (subscription.current) {
      // eslint-disable-next-line
      subscription.current.unsubscribe();
      subscription.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return {
    subscription,
    unsubscribe,
  };
};

export default useSubscription;
