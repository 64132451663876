import {
  SESSION_EXPIRED,
  MOCK_LOGIN,
  ACCESS_TOKEN_EXPIRES_AT,
  REFRESH_TOKEN_EXPIRES_AT,
} from './../constants/index';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {WITHCREDENTIALS} from '../constants';
import {RefreshTokenResponse, ResponseDefaultError} from '../interface';
import {setLogoutAndClearData, setMaintenance, setNotInMaintenance} from '../redux/features/authSlice';
import store from '../redux/store';
import isExpiresd from '../utils/isExpires';
import {refreshToken} from '../services';
import {BehaviorSubject, filter, finalize, firstValueFrom, map} from 'rxjs';
import {getString} from '../utils/storageUtils';
// import {logout} from '../services';
// import {getCodeChallengeAndVerifier} from '../utils/appUtils'

// const logoutFn = () => {
//   window.history.go(0);
//   store.dispatch(setLogoutAndClearData());
//   getCodeChallengeAndVerifier()
// };

const nullRefreshTokenResponse: RefreshTokenResponse = {
  status: '',
  result: { access_token_expires_at: '', refresh_token_expires_at: '' },
};

//Define global parameter of axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshTokenSubcribe = (
  refreshTokenObservable: BehaviorSubject<RefreshTokenResponse>,
  config: AxiosRequestConfig
) => {
  return firstValueFrom(
    refreshTokenObservable.pipe(
      filter((res) => res !== nullRefreshTokenResponse),
      map(() => {
        return config;
      }),
    )
  );
};

// eslint-disable-next-line
const reqInterceptor = (config: AxiosRequestConfig): any => {
  // mock
  if (config.url?.endsWith('.json') && config.baseURL !== '/') {
    config.baseURL = process.env.REACT_APP_MOCK_API_BASE_URL;
  }
  config.withCredentials = config.withCredentials ?? WITHCREDENTIALS;
  if (!MOCK_LOGIN) {
    const accessTokenExpires = getString(ACCESS_TOKEN_EXPIRES_AT, '');
    const refreshTokenExpires = getString(REFRESH_TOKEN_EXPIRES_AT, '');
    if (isExpiresd(accessTokenExpires)) {
      if (isExpiresd(refreshTokenExpires)) {
        store.dispatch(setLogoutAndClearData());
        sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
      } else {
        if (!window.refreshToken || window.refreshToken.originalAccessTokenExpireTime !== accessTokenExpires) {
          window.refreshToken = {
            originalAccessTokenExpireTime: accessTokenExpires,
            refreshToken$: new BehaviorSubject<RefreshTokenResponse>(nullRefreshTokenResponse)
          };
          window.refreshTokenSubscription = refreshToken()
            .pipe(
              finalize(() => {
                window.refreshTokenSubscription = undefined;
              })
            )
            .subscribe({
              next: (val) => {
                window.refreshToken?.refreshToken$.next(val);
              },
              error: (err) => {
                window.refreshToken?.refreshToken$.error(err);
              },
            });
        }
        return refreshTokenSubcribe(window.refreshToken.refreshToken$, config);
      }
    } else {
      return config;
    }
  } else {
    return config;
  }
};

axiosInstance.interceptors.request.use(reqInterceptor, (err) =>
  Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (response: any) => {
    if(response.data.statusCode === 503 && response.data.flag.includes('MaintenanceInProgress')) {
      store.dispatch(setMaintenance({
        payload: response.data.result,
        type: "result"
      }))
      return;
    } else {
      store.dispatch(setNotInMaintenance());
      return response;
    }
  },
  (error: AxiosError<ResponseDefaultError>) => {
    if (error.response?.status === 403) {
      store.dispatch(setLogoutAndClearData());
      sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
      window.history.go(0);
    }
    return Promise.reject({
      status: error.response?.status,
      error: error.response?.data,
    });
  },
);
