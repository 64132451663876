import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../interface';

// Define a type for the slice state
interface OrganizationState {
  // better use this as lookup with LookupContext, than use redux selector
  roles: Role[];
}

// Define the initial state using that type
const initialState: OrganizationState = {
  roles: [],
};

export const platformSlice = createSlice({
  name: 'Organization',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearPlatformSlice() {
      return initialState;
    },
    setRoles(state, action: PayloadAction<{ roles: Role[] }>) {
      const { roles } = action.payload;
      state.roles = roles;
    },
  },
});

export const { clearPlatformSlice, setRoles } = platformSlice.actions;

export default platformSlice.reducer;
