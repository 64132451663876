import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LookupProvider } from './components/LookupContext';
import OrganizationDetailsProvider from './components/OrganizationDetailsContext';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UserOrganizationListProvider from './components/UserOrganizationListContext';
import WebFontLoaderProvider from './components/WebFontLoaderContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <WebFontLoaderProvider>
      <LookupProvider>
        <UserOrganizationListProvider>
          <OrganizationDetailsProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App />
            </LocalizationProvider>
          </OrganizationDetailsProvider>
        </UserOrganizationListProvider>
      </LookupProvider>
    </WebFontLoaderProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
