import moment from "moment";
import { DATE_FORMAT_SHORT, TILL_DATE_START } from "../constants";
import { DateRange, TimePeriod } from "../interface";
import _, { AnyKindOfDictionary } from "lodash";

export function convertToAPIDateTimeString(date: string | undefined) {
	return date ? moment(date).toISOString() : undefined;
}

/**
 * Calculate date range
 * @param range time range
 * @param momentBase moment instance to calculate the date range
 * @returns DateRange
 */
export function getCalendarDateRange(
	range: "today" | "week" | "month" | "quarter" | "year",
	momentBase = moment()
) {
	if (range === "today") {
		return {
			startDate: momentBase.startOf("day").toISOString(),
			endDate: momentBase.endOf("day").toISOString()
		};
	} else {
		return {
			startDate: momentBase.startOf(range).toISOString(),
			endDate: momentBase.endOf(range).toISOString()
		};
	}
}

/**
 * Calculate day range for last N days
 * @param days day number offset
 * @param momentBase moment instance to calculate the range
 * @returns DateRange
 */
export function getLastNDaysRange(days: number, momentBase = moment()): DateRange {
	const endDate = momentBase.clone().endOf("day");
	return {
		endDate: endDate.clone().endOf("day").toISOString(),
		startDate: endDate
			.add(0 - days, "day")
			.startOf("day")
			.toISOString()
	};
}

/**
 * Get date range with a preset specific time period
 * @param timePeriod a specific time period
 * @returns DateRange
 */
export function getPresetDateRange(
	timePeriod: Omit<TimePeriod, TimePeriod.Custom | TimePeriod.CurrentSession>
): DateRange {
	const current = moment();
	switch (timePeriod) {
		case TimePeriod.CurrentSession: {
			return {
				//startDate: new Date(getNumber(LOGGED_IN_TIMESTAMP, 0)).toISOString(),
				startDate: current.startOf("day").toISOString(),
				endDate: current.endOf("day").toISOString()
			};
		}
		case TimePeriod.Today: {
			return getCalendarDateRange("today", current);
		}
		case TimePeriod.ThisWeek: {
			return getCalendarDateRange("week", current);
		}
		case TimePeriod.ThisMonth: {
			return getCalendarDateRange("month", current);
		}
		case TimePeriod.ThisQuarter: {
			return getCalendarDateRange("quarter", current);
		}
		case TimePeriod.ThisYear: {
			return getCalendarDateRange("year", current);
		}
		case TimePeriod.LastWeek: {
			return getCalendarDateRange("week", current.add(-7, "day"));
		}
		case TimePeriod.LastMonth: {
			return getCalendarDateRange("month", current.add(-1, "month"));
		}
		case TimePeriod.LastQuarter: {
			return getCalendarDateRange("month", current.add(-3, "month"));
		}
		case TimePeriod.LastYear: {
			return getCalendarDateRange("year", current.add(-1, "year"));
		}
		case TimePeriod.Last7Days: {
			return getLastNDaysRange(7);
		}
		case TimePeriod.Last30Days: {
			return getLastNDaysRange(30);
		}
		case TimePeriod.Last90Days: {
			return getLastNDaysRange(90);
		}
		case TimePeriod.TillDate: {
			return {
				startDate: moment(TILL_DATE_START).startOf('day').toISOString(),
				endDate: current.endOf("day").toISOString()
			};
		}
		default: {
			throw new Error("Unsupported preset range");
		}
	}
}

/**
 * Format DateRange into text
 * @param dateRange dateRange
 * @returns formatted DateRange text
 */
export function formatDateRange(dateRange: DateRange) {
	const startStr = dateRange.startDate
		? `From ${moment(dateRange.startDate).format(DATE_FORMAT_SHORT)}`
		: "";
	const endStr = dateRange.endDate
		? `to ${moment(dateRange.endDate).add(-1, "second").format(DATE_FORMAT_SHORT)}`
		: "";
	return [startStr, endStr].join(" ");
}

export const getFormats = (index) => {
	let result: any = "";
	if (index.includes("T")) {
		const format = moment(index, "YYYY-MM-DTH").format("YYYY-M-D");
		result = moment(format).valueOf();
	} else if (index.includes("W")) {
		const format = moment(index, "GGGG-[W]WW").format("YYYY-M-D");
		result = moment(format).valueOf();
	} else if (moment(index, "YYYY-M-D", true).isValid()) {
		const format = moment(index, "YYYY-M-D").format("YYYY-M-D");
		result = moment(format).valueOf();
	} else {
		result = moment(index, "YYYY-M").valueOf();
	}
	return result;
};

export const getSensitivity = (data) => {
	if (data.includes("Month") || data.includes("Days")) {
		return "daily";
	} else if (data.includes("Quarter")) {
		return "weekly";
	} else if (data.includes("Year")) {
		return "monthly";
	} else if (data.includes("Till")) {
		return "monthly";
	} else {
		return "daily";
	}
};

export const getCumulativeCount = (array, index) => {
	let cumulativeCount = 0;
	for (let i = index; i >= 0; i--) {
		cumulativeCount += array[i][1];
	}
	return cumulativeCount;
};

export const calculateDuplicates = (data) => {
	const keys = Array.from(new Set(data.map((el) => el[0])));
	const summed = keys.map((key) => [
		key,
		data.reduce((sum, el) => sum + (el[0] == key ? el[1] : 0), 0)
	]);
	return summed;
};

export const getMonthly = (array) => {
	const jan = array.filter((arr) => moment(arr[0]).month() == 1);
	const feb = array.filter((arr) => moment(arr[0]).month() == 2);
	const mar = array.filter((arr) => moment(arr[0]).month() == 3);
	const apr = array.filter((arr) => moment(arr[0]).month() == 4);
	const may = array.filter((arr) => moment(arr[0]).month() == 5);
	const jun = array.filter((arr) => moment(arr[0]).month() == 6);
	const jul = array.filter((arr) => moment(arr[0]).month() == 7);
	const aug = array.filter((arr) => moment(arr[0]).month() == 8);
	const sep = array.filter((arr) => moment(arr[0]).month() == 9);
	const oct = array.filter((arr) => moment(arr[0]).month() == 10);
	const nov = array.filter((arr) => moment(arr[0]).month() == 11);
	const dec = array.filter((arr) => moment(arr[0]).month() == 12);

	//sum of each month values 
	const janSum = jan.reduce((a, b) => a + b[1], 0);
	const febSum = feb.reduce((a, b) => a + b[1], 0);
	const marSum = mar.reduce((a, b) => a + b[1], 0);
	const aprSum = apr.reduce((a, b) => a + b[1], 0);
	const maySum = may.reduce((a, b) => a + b[1], 0);
	const junSum = jun.reduce((a, b) => a + b[1], 0);
	const julSum = jul.reduce((a, b) => a + b[1], 0);
	const augSum = aug.reduce((a, b) => a + b[1], 0);
	const sepSum = sep.reduce((a, b) => a + b[1], 0);
	const octSum = oct.reduce((a, b) => a + b[1], 0);
	const novSum = nov.reduce((a, b) => a + b[1], 0);
	const decSum = dec.reduce((a, b) => a + b[1], 0);

	const janArray = jan.length > 0 ? [[moment(jan[0][0]).startOf("month").valueOf(), janSum]] : [];
	const febArray = feb.length > 0 ? [[moment(feb[0][0]).startOf("month").valueOf(), febSum]] : [];
	const marArray = mar.length > 0 ? [[moment(mar[0][0]).startOf("month").valueOf(), marSum]] : [];
	const aprArray = apr.length > 0 ? [[moment(apr[0][0]).startOf("month").valueOf(), aprSum]] : [];
	const mayArray = may.length > 0 ? [[moment(may[0][0]).startOf("month").valueOf(), maySum]] : [];
	const junArray = jun.length > 0 ? [[moment(jun[0][0]).startOf("month").valueOf(), junSum]] : [];
	const julArray = jul.length > 0 ? [[moment(jul[0][0]).startOf("month").valueOf(), julSum]] : [];
	const augArray = aug.length > 0 ? [[moment(aug[0][0]).startOf("month").valueOf(), augSum]] : [];
	const sepArray = sep.length > 0 ? [[moment(sep[0][0]).startOf("month").valueOf(), sepSum]] : [];
	const octArray = oct.length > 0 ? [[moment(oct[0][0]).startOf("month").valueOf(), octSum]] : [];
	const novArray = nov.length > 0 ? [[moment(nov[0][0]).startOf("month").valueOf(), novSum]] : [];
	const decArray = dec.length > 0 ? [[moment(dec[0][0]).startOf("month").valueOf(), decSum]] : [];

	const result = janArray.concat(
		febArray,
		marArray,
		aprArray,
		mayArray,
		junArray,
		julArray,
		augArray,
		sepArray,
		octArray,
		novArray,
		decArray
	);
	return result;
};

export const getCumulativeMonthly = (array) => {
	const jan = array.filter((arr) => moment(arr[0]).month() == 1);
	const feb = array.filter((arr) => moment(arr[0]).month() == 2);
	const mar = array.filter((arr) => moment(arr[0]).month() == 3);
	const apr = array.filter((arr) => moment(arr[0]).month() == 4);
	const may = array.filter((arr) => moment(arr[0]).month() == 5);
	const jun = array.filter((arr) => moment(arr[0]).month() == 6);
	const jul = array.filter((arr) => moment(arr[0]).month() == 7);
	const aug = array.filter((arr) => moment(arr[0]).month() == 8);
	const sep = array.filter((arr) => moment(arr[0]).month() == 9);
	const oct = array.filter((arr) => moment(arr[0]).month() == 10);
	const nov = array.filter((arr) => moment(arr[0]).month() == 11);
	const dec = array.filter((arr) => moment(arr[0]).month() == 12);

	const janArray =
		jan.length > 0 ? [[moment(jan[0][0]).startOf("month").valueOf(), jan[jan.length - 1][1]]] : [];
	const febArray =
		feb.length > 0 ? [[moment(feb[0][0]).startOf("month").valueOf(), feb[feb.length - 1][1]]] : [];
	const marArray =
		mar.length > 0 ? [[moment(mar[0][0]).startOf("month").valueOf(), mar[mar.length - 1][1]]] : [];
	const aprArray =
		apr.length > 0 ? [[moment(apr[0][0]).startOf("month").valueOf(), apr[apr.length - 1][1]]] : [];
	const mayArray =
		may.length > 0 ? [[moment(may[0][0]).startOf("month").valueOf(), may[may.length - 1][1]]] : [];
	const junArray =
		jun.length > 0 ? [[moment(jun[0][0]).startOf("month").valueOf(), jun[jun.length - 1][1]]] : [];
	const julArray =
		jul.length > 0 ? [[moment(jul[0][0]).startOf("month").valueOf(), jul[jul.length - 1][1]]] : [];
	const augArray =
		aug.length > 0 ? [[moment(aug[0][0]).startOf("month").valueOf(), aug[aug.length - 1][1]]] : [];
	const sepArray =
		sep.length > 0 ? [[moment(sep[0][0]).startOf("month").valueOf(), sep[sep.length - 1][1]]] : [];
	const octArray =
		oct.length > 0 ? [[moment(oct[0][0]).startOf("month").valueOf(), oct[oct.length - 1][1]]] : [];
	const novArray =
		nov.length > 0 ? [[moment(nov[0][0]).startOf("month").valueOf(), nov[nov.length - 1][1]]] : [];
	const decArray =
		dec.length > 0 ? [[moment(dec[0][0]).startOf("month").valueOf(), dec[dec.length - 1][1]]] : [];

	const result = janArray.concat(
		febArray,
		marArray,
		aprArray,
		mayArray,
		junArray,
		julArray,
		augArray,
		sepArray,
		octArray,
		novArray,
		decArray
	);
	return result;
};

export const getDateParams = ({ from, to, dateText, sensitivity }) => {
	let obj: any = {
		start_year: moment(from).year(),
		start_month: moment(from).month() + 1,
		start_week: moment(from).week(),
		start_day: moment(from).date(),
		start_hour: moment(from).hour(),
		end_year: moment(to).year(),
		end_month: moment(to).month() + 1,
		end_week: moment(to).week(),
		end_day: moment(to).date(),
		end_hour: moment(to).hour()
	};

	if (sensitivity === "weekly") {
		obj.end_week = moment(to).isoWeek();
		obj = _.pick(obj, ["start_year", "start_week", "end_year", "end_week"]);
	} else if (sensitivity === "monthly") {
		obj = _.pick(obj, ["start_year", "start_month", "end_year", "end_month"]);
	} else if (sensitivity === "daily") {
		obj = _.omit(obj, ["start_hour", "end_hour", "start_week", "end_week"]);
	}
	return obj;
};

export const getValueOfKeys = (key) => {
	return moment(key).valueOf();
};

export const getProcessedData = (method, data) => {
    const processedData: any = [];
    const monthlyData = data;

    monthlyData.forEach((el, index) => {
      if (
        index === 0 ||
        !method(new Date(el[0]), new Date(monthlyData[index - 1][0]))
      ) {
        processedData.push(el);
      } else {
        processedData[processedData.length - 1][1] += el[1];
      }
    });

    return processedData;
};


export const getCumulativeFinal = (data) => {
	const final = data.map((value, index) => {
		return [
			value[0], getCumulativeCount(data, index)
		];
	})
	return final;
}
