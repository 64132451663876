import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

// Define a type for the slice state
interface MenuState {
  isExpanded: boolean;
}

// Define the initial state using that type
const initialState: MenuState = {
  isExpanded: true,
};

export const menuSlice = createSlice({
  name: 'menu',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearMenuSlice() {
      return initialState;
    },
    updateMenu: (state, action: PayloadAction<MenuState>) => {
      state.isExpanded = action.payload.isExpanded;
    },
  },
});

export const {clearMenuSlice, updateMenu} = menuSlice.actions;
export const menuState = (state: RootState) => state.menu;
export default menuSlice.reducer;
