import React from 'react';
import {Navigate} from 'react-router-dom';
import {PLATFORM_ADMIN} from '../../constants';
import {useAppSelector} from '../../hook/useRedux';
import {CustomUser} from '../../interface';
import {
  selectAuthUser,
  selectIsAuthenticated,
} from '../../redux/selectors/authSlice';

function RequirePlatformAuth({children}: {children: JSX.Element}) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authUser = useAppSelector(selectAuthUser) as CustomUser;

  return !isAuthenticated || authUser.role !== PLATFORM_ADMIN ? (
    <Navigate to='/login' />
  ) : (
    children
  );
}

export default RequirePlatformAuth;
