import {Alert, Box, Button, Divider, Snackbar} from '@mui/material';
import React from 'react';
import { DURATION } from '../../constants';
import {ReactComponent as SuccessfulIcon} from '../../images/successful.svg';
import ErrorIcon from '@mui/icons-material/Error';

export interface AlertItem {
  color?: 'success' | 'error';
  message: string;
}

interface Props {
  color?: 'success' | 'error';
  isShow: boolean;
  message: string;
  dismiss: () => void;
}

function Toast({color = 'success', isShow, message, dismiss}: Props) {
  return (
    <Snackbar
      color={color}
      open={isShow}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      autoHideDuration={DURATION}
      onClose={dismiss}
      sx={{mt: 9.5}}
    >
      <Alert
        sx={{py: 0, '& .MuiAlert-message': {
          fontSize: '1.072em'
        }}}
        color={color}
        icon={color === 'success' ? <SuccessfulIcon /> : <ErrorIcon />}
        onClose={dismiss}
        action={
          <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <Divider orientation='vertical' flexItem />
            <Button
              sx={{
                color: 'inherit',
              }}
              onClick={dismiss}
            >
              Dismiss
            </Button>
          </Box>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
