import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import { ResponseDefaultError } from '../../interface';
import { getResponseError } from '../../utils/alertUtils';

// Define a type for the slice state
interface SnackbarState {
  isShow: boolean;
  color?: 'success' | 'error';
  message?: string;
}

// Define the initial state using that type
const initialState: SnackbarState = {
  isShow: false,
};

export const counterSlice = createSlice({
  name: 'snackbar',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateSnackbar(state, action: PayloadAction<SnackbarState>) {
      const { isShow, color, message } = action.payload;
      state.isShow = isShow;
      if (color) {
        state.color = color;
      }
      if (message) {
        state.message = message;
      }
    },
    updateSnackbarWithApiError(state, action: PayloadAction<{ status?: number, error?: ResponseDefaultError }>) {
      const { status, error } = action.payload;
      state.isShow = true;
      state.color = 'error';
      state.message = getResponseError({ status, error });
    }
  },
});

export const {updateSnackbar, updateSnackbarWithApiError} = counterSlice.actions;
export default counterSlice.reducer;
