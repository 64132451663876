const publicImage = (url: string | undefined) => {
  if (url?.startsWith('http://') || url?.startsWith('https://')) {
    return url;
  } else {
    return url && process.env.PUBLIC_URL
      ? process.env.PUBLIC_URL + '/assets/images/' + url
      : '';
  }
};

export default publicImage;
