import {NOTIFICATIONS} from './../constants/index';
const updateNotificationsStorage = (value: string) => {
  localStorage.setItem(NOTIFICATIONS, value);
};

const clearNotificationsStorage = () => {
  localStorage.removeItem(NOTIFICATIONS);
};

const getNotificationsStorage = () => {
  if (localStorage.getItem(NOTIFICATIONS)) {
    return JSON.parse(localStorage.getItem(NOTIFICATIONS) as string);
  } else {
    return [];
  }
};

export {
  updateNotificationsStorage,
  clearNotificationsStorage,
  getNotificationsStorage,
};
