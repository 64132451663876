import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Observable } from 'rxjs';
import {
    PaginationResponse,
    OrganizationRequests,
    OrganizationRequestsResponse,
} from '../interface';
import { dataURLtoFile } from '../utils/fileUtils';
import { axiosRequest } from './http';
import { axiosInstance as Axios } from '../api/fetchData';
import { wrapAxiosResToDataObservable } from './http';

export function getOrganizationRequests(
    data: OrganizationRequests & { is_active?: boolean },
): Observable<PaginationResponse<OrganizationRequestsResponse>> {
    return axiosRequest({
        method: 'GET',
        url: `/diceidd/customer-request/search`,
        params: data,
    }) as Observable<PaginationResponse<OrganizationRequestsResponse>>;
}

//Mock
// export function getOrganizationRequests(
//     data: OrganizationRequests,
// ): Observable<PaginationResponse<OrganizationRequestsResponse>> {
//     return wrapAxiosResToDataObservable(
//         Axios.get('organizationRequests.json', { data: data }),
//     ) as Observable<PaginationResponse<OrganizationRequestsResponse>>;
// }

export function approveOrganizationRequests(
    data: any,
): Observable<AxiosResponse> {
    return axiosRequest({
        method: 'POST',
        url: `/customer-request/approve`,
        data: data,
    }) as Observable<AxiosResponse>;
}
