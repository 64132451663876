import { Observable } from 'rxjs';
import { ListAllWebFontsResponse } from '../interface/fonts';
import { axiosRequest } from './http';

export function listAllWebFonts(): Observable<ListAllWebFontsResponse> {
  return axiosRequest({
    method: 'GET',
    baseURL: '/',
    url: '/assets/webfonts-list.json',
  }) as Observable<ListAllWebFontsResponse>;
}
