import {checkUserNormal} from '../../utils/permissionUtils';
import {RootState} from '../store';

export function selectAuthState(state: RootState) {
  return state.auth;
}

export function selectAuthUser(state: RootState) {
  return selectAuthState(state).user;
}

export function selectIsAuthenticated(state: RootState) {
  return !!selectAuthState(state).user;
}

export function selectIsInMaintenance(state: RootState) {
  return selectAuthState(state).maintenance;
}

export function selectIsNormalUser(state: RootState) {
  const authUser = selectAuthUser(state);
  return authUser && checkUserNormal(authUser);
}
