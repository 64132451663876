import {Observable} from 'rxjs';
import {
  ReportsResponse,
  SendReportsRequest,
  ReportsFileRequest,
  CommonPlatformTableRequest,
  Connection,
  PaginationResponse,
  ReportSchemaResponse,
  ReportTemplateResponse,
  ReportOrgUserResponse,
  ReportDepartmentResponse,
} from '../interface';
import {axiosRequest} from './http';
import _, {forIn} from 'lodash';
import {ALL, ORG_REPORTSTYPE, REPORTSTYPE} from '../constants';
import {axiosInstance} from '../api/fetchData';

export function getReportsOverview(data: {
  from_date?: string;
  to_date?: string;
}): Observable<ReportsResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/organization/report`,
    params: data,
  }) as Observable<ReportsResponse>;
}

export function sendReports(data: SendReportsRequest): Observable<string> {
  const {section_list, email, ...rest} = data;
  const params = new URLSearchParams();
  if (_.isEqual(section_list, ORG_REPORTSTYPE)) {
    params.append('section_list', ALL);
  } else {
    params.append('section_list', _.toString(section_list));
  }
  params.append('email', _.toString(email));
  forIn(rest, (v, k) => {
    !_.isUndefined(v) && !_.isEmpty(v) && params.append(k, _.toString(v));
  });
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/organization/report/email`,
    params: params,
  }) as Observable<string>;
}

export function getReportsFile(data: ReportsFileRequest) {
  const {section_list, ...rest} = data;
  const params = new URLSearchParams();
  if (_.isEqual(section_list, ORG_REPORTSTYPE)) {
    params.append('section_list', ALL);
  } else {
    params.append('section_list', _.toString(section_list));
  }
  forIn(rest, (v, k) => {
    !_.isUndefined(v) && !_.isEmpty(v) && params.append(k, _.toString(v));
  });
  return axiosInstance({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/organization/report/download`,
    params: params,
    headers: {
      Accept: 'application/*',
    },
    responseType: 'blob',
  });
}

export function getPlatformReportsOverview(data: {
  from_date?: string;
  to_date?: string;
  org_id?: string;
  include_all_orgs: boolean;
}): Observable<ReportsResponse> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report`,
    params: data,
    headers: {
      org_id,
    },
  }) as Observable<ReportsResponse>;
}

export function sendPlatformReports(
  data: Omit<SendReportsRequest, 'org_id'> & {
    org_id?: string;
    include_all_orgs: boolean;
  },
): Observable<string> {
  const {section_list, email, include_all_orgs, org_id, include_inactive, ...rest} = data;
  const params = new URLSearchParams();
  if (_.isEqual(section_list, REPORTSTYPE)) {
    params.append('section_list', ALL);
  } else {
    params.append('section_list', _.toString(section_list));
  }
  if(org_id) {
    params.append('org_id', org_id);
  }
  email.forEach((mail) => params.append('email_id', _.toString(mail)));
  //params.append('email_id', _.toString(email));
  params.append('include_all_orgs', JSON.stringify(include_all_orgs));
  params.append('include_inactive', JSON.stringify(include_inactive));
  forIn(rest, (v, k) => {
    !_.isUndefined(v) && !_.isEmpty(v) && params.append(k, _.toString(v));
  });
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/email`,
    params: params,
    headers: {
      org_id,
    },
  }) as Observable<string>;
}

export function getPlatformReportsFile(
  data: Omit<ReportsFileRequest, 'org_id'> & {
    org_id?: string;
    include_all_orgs: boolean;
  },
) {
  const {section_list, include_all_orgs, org_id, include_inactive, ...rest} = data;
  const params = new URLSearchParams();
  if (_.isEqual(section_list, REPORTSTYPE)) {
    params.append('section_list', ALL);
  } else {
    params.append('section_list', _.toString(section_list));
  }
  if(org_id) {
    params.append('org_id', org_id);
  }
  params.append('include_all_orgs', JSON.stringify(include_all_orgs));
  params.append('include_inactive', JSON.stringify(include_inactive));
  forIn(rest, (v, k) => {
    !_.isUndefined(v) && !_.isEmpty(v) && params.append(k, _.toString(v));
  });
  return axiosInstance({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/download`,
    params: params,
    headers: {
      org_id,
      Accept: 'application/*',
    },
    responseType: 'blob',
  });
}

export function getPlatformOrgUser(
  data: Omit<CommonPlatformTableRequest, 'status'>,
): Observable<PaginationResponse<ReportOrgUserResponse>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/org-user`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<ReportOrgUserResponse>>;
}

export function getPlatformDepartment(
  data: Omit<CommonPlatformTableRequest, 'status'>,
): Observable<PaginationResponse<ReportDepartmentResponse>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/department`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<ReportDepartmentResponse>>;
}

export function getPlatformConnection(
  data: CommonPlatformTableRequest,
): Observable<PaginationResponse<Connection>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/connection`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<Connection>>;
}

export function getPlatformCredential(
  data: CommonPlatformTableRequest,
): Observable<PaginationResponse<Connection>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/credential`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<Connection>>;
}

export function getPlatformVerification(
  data: CommonPlatformTableRequest,
): Observable<PaginationResponse<Connection>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/verification`,
    params: data,
  }) as Observable<PaginationResponse<Connection>>;
}

export function getPlatformSchema(
  data: Omit<CommonPlatformTableRequest, 'status'>,
): Observable<PaginationResponse<ReportSchemaResponse>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/schema`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<ReportSchemaResponse>>;
}

export function getPlatformTemplate(
  data: Omit<CommonPlatformTableRequest, 'status'>,
): Observable<PaginationResponse<ReportTemplateResponse>> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/report/template`,
    params: rest,
    headers: {
      org_id,
    },
  }) as Observable<PaginationResponse<ReportTemplateResponse>>;
}
