import {
  GetSubscriptionRequest,
  PaginationResponse,
  Subscription,
  GetPlansRequest,
  Plan
} from "../interface";
import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import { axiosRequest, wrapAxiosResToDataObservable } from './http';
import _ from 'lodash';
import { axiosInstance as Axios, axiosInstance } from '../api/fetchData';

export function getSubscriptions(
  data: GetSubscriptionRequest,
): Observable<PaginationResponse<Subscription>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/subscription/search`,
    params: data,
  }) as Observable<PaginationResponse<Subscription>>;
}

export function getSubscriptionById(
  sub_id: string,
): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/subscription/${sub_id}`,
  }) as Observable<any>;
}

//Mock Subscriptions
// export function getSubscriptions(
//   data: GetSubscriptionRequest,
// ): Observable<PaginationResponse<Subscription>> {
//   return wrapAxiosResToDataObservable(
//     Axios.get('subsMock.json', {data: data}),
//   ) as Observable<PaginationResponse<Subscription>>;
// }

export function getPlans(
  data: GetPlansRequest,
): Observable<PaginationResponse<Plan>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/plan/search`,
    params: data,
  }) as Observable<PaginationResponse<Plan>>;
}

export function getPlanById(
  plan_id: string,
): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/plan/${plan_id}`,
  }) as Observable<any>;
}

//Mock Plans
// export function getPlans(
//   data: GetPlansRequest,
// ): Observable<PaginationResponse<Plan>> {
//   return wrapAxiosResToDataObservable(
//     Axios.get('plansMock.json', {data: data}),
//   ) as Observable<PaginationResponse<Plan>>;
// }

// export function getOrganizations(
//   data: any,
// ): Observable<any> {
//   return axiosRequest({
//     baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
//     method: 'GET',
//     url: `/org`,
//     params: data,
//   }) as Observable<any>;
// }

//Mock Organizations
// export function getOrganizations(
//   data: any,
// ): Observable<any> {
//   return wrapAxiosResToDataObservable(
//     Axios.get('orgMock.json', {data: data}),
//   ) as Observable<any>;
// }

export function createPlanService(
  data: any,
): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/plan`,
    data: data,
  }) as Observable<any>;
}

export function updatePlanService(
  data: any
): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'PATCH',
    url: `/plan/${data.plan_id}`,
    data: data.payload,
  }) as Observable<any>;
}

export function deletePlanService(
  plan_id: any
): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'DELETE',
    url: `/plan/${plan_id}`,
  }) as Observable<any>;
}

export function subsAndPlansService(includeCustomPlans = true): Observable<any> {
  const params = new URLSearchParams();
  params.append('include_custom_plans', _.toString(includeCustomPlans));
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    params: params,
    url: `/public/subscriptions-plans`,
  }) as Observable<any>;
}

//Mock Subs And Plans
// export function subsAndPlansService(): Observable<any> {
//   return wrapAxiosResToDataObservable(
//     Axios.get('publicSubPlanMock.json'),
//   ) as Observable<any>;
// }
