import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  USER_PROFILE,
  USER_SELECTED_DEPT_ID,
  USER_SELECTED_ORG_ID,
} from '../../constants/storage';
import {
  DepartmentOverview,
  DepartmentPreview,
  OrganizationDetails,
  OrganizationPreview,
  OrgUser,
} from '../../interface';
import { getObject, getString, setString } from '../../utils/storageUtils';

// Define a type for the slice state
interface OrganizationState {
  currentOrgId: string;
  departmentOverviewInited: boolean;
  departmentOverview: DepartmentOverview;
  isLoadingDetails: boolean;
  organizationDetails: OrganizationDetails | undefined;
  isLoadingUserOrganizationList: boolean;
  userOrganizationList: OrganizationPreview[] | undefined;
  currentDepartmentId: string | undefined;
}

// Define the initial state using that type
const initialState: OrganizationState = {
  currentOrgId:
    (getString(USER_SELECTED_ORG_ID, '') ||
      getObject<OrgUser>(USER_PROFILE, null)?.org_id) ??
    '',
  departmentOverviewInited: false,
  departmentOverview: {
    total_dept_count: 0,
    dept_list: [],
    invoked_by: '',
    org_id: '',
    active_dept_count: 0,
    inactive_dept_count: 0,
  },
  isLoadingDetails: false,
  organizationDetails: undefined,
  isLoadingUserOrganizationList: false,
  userOrganizationList: undefined,
  currentDepartmentId:
    getString(USER_SELECTED_DEPT_ID, '') ||
    (getObject<OrgUser>(USER_PROFILE, null)?.dept_and_roles || []).find(
      (item) => item.is_favorite
    )?.dept_id,
};

export const organizationSlice = createSlice({
  name: 'Organization',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearOrganizationSlice() {
      setString(USER_SELECTED_DEPT_ID, '');
      return {...initialState, currentDepartmentId: undefined};
    },
    setCurrentOrgId(state, action: PayloadAction<{ orgId: string }>) {
      const { orgId } = action.payload;
      if (state.currentOrgId !== orgId) {
        setString(USER_SELECTED_ORG_ID, orgId);
        return {
          ...initialState,
          currentOrgId: orgId,
        };
      }
    },
    setIsLoadingDetails(state, action: PayloadAction<{ loading: boolean }>) {
      const { loading } = action.payload;
      state.isLoadingDetails = loading;
    },
    setOrganization(
      state,
      action: PayloadAction<{ org: OrganizationDetails }>
    ) {
      const { org } = action.payload;
      state.organizationDetails = org;
      if(!getString(USER_SELECTED_ORG_ID, '')) {
        setString(USER_SELECTED_ORG_ID, org.id);
        state.currentOrgId = org.id;
      }
    },
    setIsLoadingUserOrganizationList(
      state,
      action: PayloadAction<{ loading: boolean }>
    ) {
      const { loading } = action.payload;
      state.isLoadingUserOrganizationList = loading;
    },
    setUserOrganizationList(
      state,
      action: PayloadAction<{ orgList: OrganizationPreview[] }>
    ) {
      const { orgList } = action.payload;
      state.userOrganizationList = orgList;
    },
    setUserFavoriteOrganization(
      state,
      action: PayloadAction<{ orgId: string }>
    ) {
      const { orgId } = action.payload;
      state.userOrganizationList = state.userOrganizationList?.map((item) => {
        item.is_favorite = item.id === orgId;
        return item;
      });
    },
    setDepartmentOverview(
      state,
      action: PayloadAction<{ departmentOverview: DepartmentOverview }>
    ) {
      const { departmentOverview } = action.payload;
      state.departmentOverviewInited = true;
      state.departmentOverview = departmentOverview;
    },
    addDepartmentToList(
      state,
      action: PayloadAction<{ dept: DepartmentPreview }>
    ) {
      const { dept } = action.payload;
      state.departmentOverview.dept_list.push(dept);
      state.departmentOverview.total_dept_count =
        state.departmentOverview.dept_list.length;
    },
    updateDepartmentInList(
      state,
      action: PayloadAction<{ dept: Partial<DepartmentPreview> }>
    ) {
      const { dept } = action.payload;
      const index = state.departmentOverview.dept_list.findIndex(
        (item) => item.dept_id === dept.dept_id
      );
      if (index >= 0) {
        state.departmentOverview.dept_list[index] = {
          ...state.departmentOverview.dept_list[index],
          ...dept,
        };
      }
    },
    deleteDepartmentFromList(state, action: PayloadAction<{ ids: string[] }>) {
      const { ids } = action.payload;
      const idsDict = ids.reduce((dict, currentItem) => {
        dict[currentItem] = true;
        return dict;
      }, {} as Record<string, boolean>);
      state.departmentOverview.dept_list =
        state.departmentOverview.dept_list.filter(
          (item) => !idsDict[item.dept_id]
        );
      state.departmentOverview.total_dept_count =
        state.departmentOverview.dept_list.length;
    },
    setCurrentDepartmentId(state, action: PayloadAction<{ deptId: string }>) {
      const { deptId } = action.payload;
      state.currentDepartmentId = deptId;
      setString(USER_SELECTED_DEPT_ID, deptId);
    },
  },
});

export const {
  clearOrganizationSlice,
  setCurrentOrgId,
  setIsLoadingDetails,
  setIsLoadingUserOrganizationList,
  setUserOrganizationList,
  setUserFavoriteOrganization,
  setOrganization,
  setDepartmentOverview,
  addDepartmentToList,
  updateDepartmentInList,
  deleteDepartmentFromList,
  setCurrentDepartmentId,
} = organizationSlice.actions;

export default organizationSlice.reducer;
