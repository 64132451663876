import {AxiosResponse} from 'axios';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {
  GetOrganizationByEmailRequest,
  GetOrgPendingItemsCountResponse,
  OnboardingRequest,
  OnboardingResponse,
  OrganizationDetails,
  UpdateOnboardingProfileRequest,
} from '../interface';
import {dataURLtoFile} from '../utils/fileUtils';
import {axiosRequest} from './http';

export function signUp(
  data: OnboardingRequest,
): Observable<OnboardingResponse> {
  return axiosRequest({
    method: 'POST',
    url: '/organization/onboard',
    data,
  }) as Observable<OnboardingResponse>;
}

export function getOrganizationByEmail(
  data: GetOrganizationByEmailRequest,
): Observable<OrganizationDetails> {
  const {email} = data;
  return axiosRequest({
    method: 'GET',
    url: `/organization/email/${email}`,
    data,
  }) as Observable<OrganizationDetails>;
}

export function updateOrgProfile(
  data: UpdateOnboardingProfileRequest,
): Observable<OrganizationDetails> {
  const {logo, ...payload} = data;
  const formData = new FormData();
  if (typeof logo === 'string' && logo !== '') {
    formData.set('logo', dataURLtoFile(logo, 'logo.png'));
  } else {
    if(typeof logo !== "undefined" && logo !== '') {
      formData.set('logo', logo);
    }
  }
  if(payload.org_profile_data.support_email === '') {
    payload.org_profile_data = _.omit(payload.org_profile_data, 'support_email');
  }
  formData.set(
    'stringifiedProfileData',
    JSON.stringify(payload.org_profile_data),
  );
  return axiosRequest({
    method: 'POST',
    url: '/organization/profile',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }) as Observable<OrganizationDetails>;
}

export function getOrganizationPendingItemsCount(): Observable<GetOrgPendingItemsCountResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: '/organization/dashboard/pending-item-count',
  }) as Observable<GetOrgPendingItemsCountResponse>;
}

export function getMockOrganizationByEmail(
  data: GetOrganizationByEmailRequest,
): Observable<OrganizationDetails> {
  const {email} = data;
  return axiosRequest({
    method: 'GET',
    url: `/organization/email/mock-login/${email}`,
    data,
  }) as Observable<OrganizationDetails>;
}

export function changeOrg(data: {
  new_org_id: string;
}): Observable<OnboardingResponse> {
  const {new_org_id} = data;
  return axiosRequest({
    method: 'POST',
    url: `login/switch-org/${new_org_id}`,
  }) as Observable<OnboardingResponse>;
}

export function logoutAll(): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_LOGIN_API_BASE_URL,
    method: 'POST',
    url: '/login/refresh-token/revoke?session=all',
  }) as Observable<AxiosResponse>;
}

export function logout(): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_LOGIN_API_BASE_URL,
    method: 'POST',
    url: '/login/refresh-token/revoke',
  }) as Observable<AxiosResponse>;
}

export function getOrganizationInfo(
  data: any,
): Observable<any> {
  return axiosRequest({
    method: 'GET',
    url: `/organization/info/`,
    params: data,
  }) as Observable<any>;
}
