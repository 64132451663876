import axios from "axios";

export const SingleTenantAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
});

export async function getSingleTenantOrgs() {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/orgs`,
        })
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve organization data"
            }
        });
    }
}

export async function getSingleTenantCount(data) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/count/${data.val}?${data.params}`,
        });
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve count data"
            }
        });
    }
} 

export async function getSingleTenantInvited(
    params,
) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/data/connection_invited?${params}`,
        })
        .catch((err) => {
            return Promise.resolve({
                data: {
                    result: {},
                    error: "Failed to retrieve Connection Invitation data"
                }
            });
        })
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve Connection Invitation data"
            }
        });
    }
} 

export async function getSingleTenantIssued(
    params,
) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/data/credential_issued?${params}`,
        });
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve Credentials Issued data"
            }
        });
    }
} 

export async function getSingleTenantRevoked(
    params,
) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/data/credential_revoked?${params}`,
        });
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve Credentials Revoked data"
            }
        });
    }
} 

export async function getSingleTenantVerified(
    params,
) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/data/credential_verified?${params}`,
        });
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve Credentials Verified data"
            }
        });
    }
} 

export const getSwitchValue = () => {
	const value = window.sessionStorage.getItem('show_all');
	if(value && value === 'true') {
		return true
	} 
	return false;
}

export async function getOrgDrillDown(data) {
    try {
        return SingleTenantAxiosInstance({
            baseURL: process.env.REACT_APP_SINGLE_TENANT_API_BASE_URL,
            method: 'GET',
            url: `/stats/data/orgs/drill-down`,
            params: data
        });
    } catch (err) {
        return await Promise.resolve({
            data: {
                result: {},
                error: "Failed to retrieve count data"
            }
        });
    }
} 
