import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {ALL_PAGE_SIZE, ENABLE_NOTIFICATION, ORG_USER} from '../../constants';
import {useAppDispatch, useAppSelector} from '../../hook/useRedux';
import useSubscription from '../../hook/useSubscription';
import {CustomUser} from '../../interface';
import {
  addNotification,
  setNotifications,
} from '../../redux/features/notificationsSlice';
import {
  selectAuthUser,
  selectIsAuthenticated,
} from '../../redux/selectors/authSlice';
import {
  selectCurrentDepartmentId,
  selectCurrentOrganizationId,
} from '../../redux/selectors/organizationSlice';
import {
  stompConnect,
  stompDisconnect,
  stompObserveIncomingMessage,
} from '../../services/stomp';
import {getNotificationsStorage} from '../../utils/updateNotificationsStoage';
import _ from 'lodash';
import {getPermissionsPromise} from '../../services';
import {setPermissions} from '../../redux/features/permissionSlice';
import {asyncPromise, flatPermissions} from '../../utils/flatPermissions';

function RequireAuth({children}: {children: JSX.Element}) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authUser = useAppSelector(selectAuthUser) as CustomUser;
  const currentOrgId = useAppSelector(selectCurrentOrganizationId);
  const currentDepId = useAppSelector(selectCurrentDepartmentId);

  const roles = _.find(authUser?.dept_and_roles, (role) =>
    _.isEqual(role.dept_id, currentDepId),
  )?.roles;

  const dispatch = useAppDispatch();
  const notificationSub = useSubscription();

  useEffect(() => {
    dispatch(setNotifications(getNotificationsStorage()));
    if (ENABLE_NOTIFICATION) {
      if (authUser?.is_admin || authUser?.is_observer) {
        if (currentOrgId) {
          const TOPOC = `/topic/notifications/${currentOrgId}`;
          stompConnect(TOPOC);
          notificationSub.subscription.current = stompObserveIncomingMessage(
            TOPOC,
          ).subscribe((res) => {
            dispatch(addNotification(res.msg));
            console.log(`STOMP received message ${res.destination}`, res.msg);
          });
        }
      } else {
        if (currentOrgId && currentDepId) {
          const rolesId = _.find(authUser?.dept_and_roles, (item) =>
            _.isEqual(item['dept_id'], currentDepId),
          )?.roles;
          const TOPICS = _.map(
            rolesId,
            (roleId) =>
              `/topic/notifications/${currentOrgId}/${currentDepId}/${roleId}`,
          );
          stompConnect(TOPICS);
          _.map(rolesId, async (roleId) => {
            const TOPOC = `/topic/notifications/${currentOrgId}/${currentDepId}/${roleId}`;
            notificationSub.subscription.current = stompObserveIncomingMessage(
              TOPOC,
            ).subscribe((res) => {
              dispatch(addNotification(res.msg));
              console.log(`STOMP received message ${res.destination}`, res.msg);
            });
          });
        }
      }
    }
  }, [authUser, currentOrgId, currentDepId]);

  useEffect(() => {
    return () => {
      ENABLE_NOTIFICATION && stompDisconnect();
    };
  }, []);

  useEffect(() => {
    if (authUser) {
      const promises = Promise.allSettled(
        _.map(roles, (role) =>
          getPermissionsPromise({_id: role, page_size: ALL_PAGE_SIZE}),
        ),
      );
      asyncPromise(promises).then((d) => {
        if (roles?.length) {
          dispatch(
            setPermissions({
              permissions: flatPermissions(d),
            }),
          );
        }
      });
    }
  }, [authUser, roles]);

  return !isAuthenticated || authUser.role !== ORG_USER ? (
    <Navigate to='/login' />
  ) : (
    children
  );
}

export default RequireAuth;
