import {Observable} from 'rxjs';
import {
  RolesOverviewResponse,
  PaginationResponse,
  RolesRequest,
  RolesResponse,
  Functionalities,
  UpdateRoleDetailsRequest,
  CreateRoleDetailsRequest,
  FunctionalitiesRequest,
  PermissionsRequest,
  PermissionsResponse,
} from '../interface';
import {axiosRequest} from './http';
import {AxiosResponse} from 'axios';
import {axiosInstance} from '../api/fetchData';

export function getRolesOverview(): Observable<RolesOverviewResponse> {
  return axiosRequest({
    method: 'GET',
    url: `/role/overview`,
  }) as Observable<RolesOverviewResponse>;
}

export function getRoles(
  data: RolesRequest,
): Observable<PaginationResponse<RolesResponse>> {
  return axiosRequest({
    method: 'GET',
    url: `diceidd/role/search`,
    params: data,
  }) as Observable<PaginationResponse<RolesResponse>>;
}

export function getRoleDetails(data: {
  _id: string;
}): Observable<RolesResponse> {
  const {_id} = data;
  return axiosRequest({
    method: 'GET',
    url: `diceidd/role/${_id}`,
  }) as Observable<RolesResponse>;
}

export function getFunctionalities(
  data: FunctionalitiesRequest,
): Observable<PaginationResponse<Functionalities>> {
  return axiosRequest({
    method: 'GET',
    url: `/functionality`,
    params: data,
  }) as Observable<PaginationResponse<Functionalities>>;
}

export function updateRoleDetails(
  data: UpdateRoleDetailsRequest,
): Observable<RolesResponse> {
  const {_id, ...rest} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/role/${_id}`,
    data: rest,
  }) as Observable<RolesResponse>;
}

export function createRoleDetails(
  data: CreateRoleDetailsRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    method: 'POST',
    url: `/role`,
    data: data,
  }) as Observable<AxiosResponse>;
}

export function deleteRoleDetails(data: {
  _id: string;
}): Observable<AxiosResponse> {
  const {_id} = data;
  return axiosRequest({
    method: 'DELETE',
    url: `/role/${_id}`,
  }) as Observable<AxiosResponse>;
}

export function getPermissions(
  data: PermissionsRequest,
): Observable<PaginationResponse<PermissionsResponse>> {
  const {_id, ...rest} = data;
  return axiosRequest({
    method: 'GET',
    url: `/role/permission/${_id}`,
    params: rest,
  }) as Observable<PaginationResponse<PermissionsResponse>>;
}

export function getPermissionsPromise(data: PermissionsRequest) {
  const {_id, ...rest} = data;
  return axiosInstance({
    method: 'GET',
    url: `/organization/role/permission/${_id}`,
    params: rest,
  });
}
