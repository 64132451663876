import {
  OrgRequest,
  PlatformAdminUser,
  PaginationResponse,
  PlatformAdminUserRequest,
  PlatformOverview,
  Org,
  InviteAdminUserRequest,
  DeactivateOrgRequest,
  CurrentOrg,
  OrgPlatformUsage,
  OrgDetailsOverviewResponse,
  PlatformOrgAdminOverview,
  OrgUser,
} from './../interface';
import {Observable} from 'rxjs';
import {axiosRequest} from './http';
import {AxiosResponse} from 'axios';
import {axiosInstance as Axios, axiosInstance} from '../api/fetchData';
import {wrapAxiosResToDataObservable} from './http';
import _ from 'lodash';

export function getPlatmentOverview(): Observable<PlatformOverview> {
  return axiosRequest({
    method: 'GET',
    url: `/org/overview`,
  }) as Observable<PlatformOverview>;
}

export function getPlatformOrgs(
  data: OrgRequest & {is_active?: boolean},
): Observable<PaginationResponse<Org>> {
  return axiosRequest({
    method: 'GET',
    url: `/org/search`,
    params: data,
  }) as Observable<PaginationResponse<Org>>;
}

export function getAllPlatformOrgs(
  data: OrgRequest & {active?: boolean}
): Observable<PaginationResponse<Org>> {
  return axiosRequest({
			method: "GET",
			url: `/dashboard/org`,
  }) as Observable<PaginationResponse<Org>>;
}

export function getPlatformAdminUsers(
  data: PlatformAdminUserRequest,
): Observable<PaginationResponse<PlatformAdminUser>> {
  return axiosRequest({
    method: 'GET',
    url: `/diceidd/org/${data.org_id}`,
    // params: data,
  }) as Observable<PaginationResponse<PlatformAdminUser>>;
}

export function getCurrentOrg(data: {org_id: string}): Observable<CurrentOrg> {
  const {org_id} = data;
  return axiosRequest({
    method: 'GET',
    url: `/organization/info`,
    headers: {
      org_id,
    },
  }) as Observable<CurrentOrg>;
}

export function inviteAdminUser(
  data: InviteAdminUserRequest,
): Observable<AxiosResponse> {
  const {org_id, ...rest} = data;
  return axiosRequest({
    method: 'POST',
    url: `/orguser`,
    data: rest,
    params: {
      org_id,
    },
  }) as Observable<AxiosResponse>;
}

export function deactivateOrg(data: {
  org_id: string;
  deleted_comments: string;
}): Observable<AxiosResponse> {
  const {org_id, deleted_comments} = data;
  axiosInstance.defaults.headers.common['org_id'] = org_id;
  // if(!_.isEmpty(deleted_comments)){
  //   axiosInstance.defaults.headers.common['deleted_comments'] = deleted_comments;
  // }
  return axiosRequest({
    method: 'DELETE',
    url: `/org/delete`,
  }) as Observable<AxiosResponse>;
}

export function updateAdminUser(
  data: InviteAdminUserRequest & {user_id: string},
): Observable<AxiosResponse> {
  const {org_id, user_id, ...rest} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/orguser/${user_id}`,
    data: rest,
    params: {
      org_id,
    },
  }) as Observable<AxiosResponse>;
}

export function deleteAdminUser(data: {org_id: string; user_id: string}) {
  const {user_id, ...rest} = data;
  return axiosRequest({
    method: 'DELETE',
    url: `/orguser/${user_id}`,
    params: {...rest},
  }) as Observable<AxiosResponse>;
}

export function reactivateOrg(
  data: DeactivateOrgRequest,
): Observable<AxiosResponse> {
  const {org_id} = data;
  axiosInstance.defaults.headers.common['org_id'] = org_id;
  return axiosRequest({
    method: 'PATCH',
    url: `/org/reactivate`,
  }) as Observable<AxiosResponse>;
}

export function getOrgDetailsOverview(data: {
  org_id: string;
}): Observable<OrgDetailsOverviewResponse> {
  const {org_id} = data;
  return axiosRequest({
    method: 'GET',
    url: `/org/overview/${org_id}`,
  }) as Observable<OrgDetailsOverviewResponse>;
}

export function getPlatformOrgAdminOverview(data: {
  user_id: string;
}): Observable<PlatformOrgAdminOverview> {
  return axiosRequest({
    method: 'GET',
    url: `/org/admin/overview`,
    params: data,
  }) as Observable<PlatformOrgAdminOverview>;
}

export function getPlatformOrgUserDetails(data: {
  user_id: string;
  org_id?: string;
}): Observable<OrgUser> {
  const {user_id, org_id} = data;
  return axiosRequest({
    method: 'GET',
    url: `/orguser/${user_id}`,
    params: {
      org_id,
    },
  }) as Observable<OrgUser>;
}

export function getPlatformAdminDetails(data: any): Observable<any> {
  const {user_id, platform_admin_id, x_pf_admin_secret_key} = data;
  if(x_pf_admin_secret_key !== '' || x_pf_admin_secret_key === undefined) {
    axiosInstance.defaults.headers.common['x-pf-admin-secret-key'] = x_pf_admin_secret_key;
    // axiosInstance.defaults.headers.common['invoked_by'] = 'ae460cb0-778e-4577-9321-6e0626708e7a'
  }
  return axiosRequest({
    method: 'GET',
    url: `/ae460cb0-778e-4577-9321-6e0626708e7a`,
  }) as Observable<any>;
}

//Mock
export function getOrgAdminPlatformUsage(
  data: OrgRequest,
): Observable<PaginationResponse<OrgPlatformUsage>> {
  return wrapAxiosResToDataObservable(
    Axios.get('platformUsage.json', {data: data}),
  ) as Observable<PaginationResponse<OrgPlatformUsage>>;
}
