import {Client} from '@stomp/stompjs';
import {filter, Subject} from 'rxjs';
import SockJS from 'sockjs-client';
import _ from 'lodash';

let stompClient: Client;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const incomingMessageSubject = new Subject<{destination: string; msg: any}>();

export function stompConnect(topic: string | string[]) {
  stompClient = new Client({
    webSocketFactory: () =>
      new SockJS(process.env.REACT_APP_NOTIFICATION_ENDPOINT || ''),
    onWebSocketClose: (params) => {
      console.log(params);
    },
  });
  stompClient.onConnect = (frame) => {
    console.log('stompClient connected', frame);
    if (stompClient.connected) {
      typeof topic === 'string'
        ? stompClient.subscribe(topic, (msg) => {
            incomingMessageSubject.next({
              destination: topic,
              msg: JSON.parse(msg.body),
            });
          })
        : _.map(topic, (t) =>
            stompClient.subscribe(t, (msg) => {
              incomingMessageSubject.next({
                destination: t,
                msg: JSON.parse(msg.body),
              });
            }),
          );
    }
  };
  stompClient.activate();
}

export function stompDisconnect() {
  if(stompClient) {
    stompClient.deactivate();
  }
}

export function stompObserveIncomingMessage(destination: string) {
  return incomingMessageSubject
    .asObservable()
    .pipe(filter((res) => res.destination === destination));
}
