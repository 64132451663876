import { RootState } from '../store';

export function selectOrganizationState(state: RootState) {
  return state.organization;
}

export function selectCurrentOrganizationId(state: RootState) {
  return selectOrganizationState(state).currentOrgId;
}

export function selectIsLoadingOrganizationDetails(state: RootState) {
  return selectOrganizationState(state).isLoadingDetails;
}

export function selectOrganizationDetails(state: RootState) {
  return selectOrganizationState(state).organizationDetails;
}

export function selectIsLoadingUserOrganizationList(state: RootState) {
  return selectOrganizationState(state).isLoadingUserOrganizationList;
}

export function selectUserOrganizationList(state: RootState) {
  return selectOrganizationState(state).userOrganizationList;
}

export function selectDepartmentOverview(state: RootState) {
  return selectOrganizationState(state).departmentOverview;
}

export function selectDepartmentOverviewIsInited(state: RootState) {
  return selectOrganizationState(state).departmentOverviewInited;
}

export function selectCurrentDepartmentId(state: RootState) {
  return selectOrganizationState(state).currentDepartmentId;
}
