import { CertificateTemplatesResponse } from "./certificate";
import { OrgUser } from './orguser';
import { SetStateAction } from 'react';
import { SchemaTemplateOption } from ".";

export type Order = 'asc' | 'desc';

export type UserOperations = 'View' | 'Edit' | 'Delete' | 'Add' | 'Verify';

export enum SortOrder {
  desc = 'DESCENDING',
  asc = 'ASCENDING',
}

export type DataType =
  | 'text'
  | 'email'
  | 'number'
  | 'date'
  | 'decimal'
  | 'boolean'
  | 'date'
  | 'datetime'
  | 'phone'
  | '';

export enum TimePeriod {
  Today = 'today',
  ThisWeek = 'thisWeek',
  ThisMonth = 'thisMonth',
  ThisQuarter = 'thisQuarter',
  ThisYear = 'thisYear',
  LastWeek = 'lastWeek',
  LastMonth = 'lastMonth',
  LastQuarter = 'lastQuarter',
  LastYear = 'lastYear',
  CurrentSession = 'currentSession',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Last90Days = 'last90Days',
  TillDate = 'tillDate',
  Custom = 'custom',
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface UnifiedResponse<T> {
  status: string;
  result: T;
}

export interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
}

export interface PaginationResponse<T> {
  result?: any;
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export interface ResponseDefaultError {
  message: string;
  status: string;
  status_code: number;
}

export interface BaseType {
  name: string;
  createdByDate?: string;
}

export interface RefObject {
  reset: () => void;
  setSelected?: (v: SetStateAction<string[]>) => void;
}

export interface CommonRequest {
  invoked_by: string;
  org_id: string;
  dept_id?: string;
}

export interface SelectOption {
  name?: string;
  label: string;
  value: string;
  group?: string;
}

export interface FileDetail {
  name: string;
  rowsProcessed: number;
  dataProcessed: string;
  dateExported: string;
}

export interface OfferForm {
  attributes: {
    [key: string]: string | number | boolean;
  };
  comments: string | null;
  schema?: SchemaTemplateOption;
  template?: Pick<CertificateTemplatesResponse, '_id' | 'name'>;
}

export interface Faq {
  name: string;
}

export interface UploadedUser {
  id: number;
  name: string;
  email: string;
}

export interface PendingItem {
  [key: string]: {
    name: string;
    total: number;
    to: string;
  }[];
}

export interface LoginResponse {
  'Account ID': string;
  Email: string;
  Name: string;
  Role: string;
}

export interface UserRole {
  role: string;
}

export type CustomUser = Partial<OrgUser> & UserRole;

export interface Point {
  x: number;
  y: number;
}

export interface BoundingBox {
  left: number;
  top: number;
  width?: number;
  height?: number;
}

export enum ROLE_PERMISSION {
  DEPARTMENTS_AND_ORG_USERS = 'Departments and Org Users',
  LOGIN_HISTORY = 'Login History',
  ORGANIZATIONS = 'Organizations',
  APIS = 'API Keys and API Calls',
  CONFIGURATIONS = 'Configurations',
  CALLBACK_CONFIGURATIONS = 'Callback Configurations',
  CUSTOMERS = 'Customers',
  SCHEMAS = 'Schemas',
  VERIFICATION = 'Credential Verification',
  VERIFICATION_TEMPLATES = 'Verification Templates',
  CERTIFICATE_TEMPLATES = 'Certificate Templates',
  EMAIL_TEMPLATES = 'Email Templates',
  CREDENTIAL_REVOCATION = 'Credential Revocation',
  CREDENTIAL_ISSUANCE = 'Credential Issuance',
}

export interface BulkPaginationResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}