import {Observable} from 'rxjs';
import {
  DeleteOrgUserRequest,
  DeleteOrgUsersRequest,
  GetOrgUsersOverviewResponse,
  GetOrgUsersRequest,
  GetOrgUsersResponse,
  OrganizationPreview,
  OrgUser,
  OrgUserRequest,
  PaginationResponse,
  SearchOrgUsersRequest,
  SetUserFavoriteDepartmentRequest,
  SetUserFavoriteOrganizationRequest,
  UpdateOrgRequest,
} from '../interface';
import {axiosRequest} from './http';
import { axiosInstance } from '../api/fetchData';
import {AxiosResponse} from 'axios';

export function getUserOrganizationList(): Observable<OrganizationPreview[]> {
  return axiosRequest({
    method: 'GET',
    url: `/orguser/orglist`,
  }) as Observable<OrganizationPreview[]>;
}

export function setUserFavOrganization(
  data: SetUserFavoriteOrganizationRequest,
): Observable<OrgUser> {
  const {new_fav_org_id} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/orguser/favorite-org/${new_fav_org_id}`,
  }) as Observable<OrgUser>;
}

export function setUserFavDepartment(
  data: SetUserFavoriteDepartmentRequest,
): Observable<OrgUser> {
  const {dept_id} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/orguser/favorite-dept/${dept_id}`,
  }) as Observable<OrgUser>;
}

export function getOrgUsers(
  data: GetOrgUsersRequest,
): Observable<GetOrgUsersResponse> {
  return axiosRequest({
    method: 'GET',
    url: '/orguser/search',
    params: data,
  }) as Observable<GetOrgUsersResponse>;
}

export function getAllOrgUsers(
  data: GetOrgUsersRequest,
): Observable<any> {
  return axiosRequest({
    method: 'GET',
    url: '/orguser/search',
    params: data,
  }) as Observable<any>;
}

export function getOrgUsersOverview(
  data: GetOrgUsersRequest,
): Observable<GetOrgUsersOverviewResponse> {
  return axiosRequest({
    method: 'GET',
    url: '/orguser/overview',
    params: data,
  }) as Observable<GetOrgUsersOverviewResponse>;
}

export function searchOrgUsersOverview(
  data: SearchOrgUsersRequest,
): Observable<PaginationResponse<OrgUser>> {
  const {dept_id, ...rest} = data;
  if (dept_id) {
    return axiosRequest({
      method: 'GET',
      url: '/orguser/search',
      params: rest,
      headers: {
        dept_id: dept_id,
      },
    }) as Observable<PaginationResponse<OrgUser>>;
  } else {
    return axiosRequest({
      method: 'GET',
      url: '/orguser/search',
      params: rest,
    }) as Observable<PaginationResponse<OrgUser>>;
  }
}

export function getOrgUserDetails(data: {
  user_id: string;
  org_id?: string;
}): Observable<OrgUser> {
  const {user_id, org_id} = data;
  return axiosRequest({
    method: 'GET',
    url: `/orguser/${user_id}`,
    headers: {
      org_id,
    },
  }) as Observable<OrgUser>;
}

export function addUserToOrg(data: OrgUserRequest): Observable<OrgUser> {
  return axiosRequest({
    method: 'POST',
    url: '/orguser',
    data,
  }) as Observable<OrgUser>;
}

export function updateOrgUser(
  data: UpdateOrgRequest & {user_id: string},
): Observable<OrgUser> {
  const {user_id, ...rest} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/orguser/${user_id}`,
    data: rest,
  }) as Observable<OrgUser>;
}

export function deleteOrgUser(data: DeleteOrgUserRequest): Observable<OrgUser> {
  const {user_id} = data;
  return axiosRequest({
    method: 'DELETE',
    url: `/orguser/${user_id}`,
  }) as Observable<OrgUser>;
}

export function getOrgUserCount(data?: {
  include_inactive: boolean;
}): Observable<number> {
  return axiosRequest({
    method: 'GET',
    url: '/orguser/count',
    params: data,
  }) as Observable<number>;
}

export function deleteOrgUsers(
  data: DeleteOrgUsersRequest,
): Observable<OrgUser> {
  return axiosRequest({
    method: 'DELETE',
    url: `/orguser/bulk`,
    data: data,
  }) as Observable<OrgUser>;
}

export function recoverOrgUserCred(data: object) {
  return axiosInstance({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    method: 'POST',
    url: `/orguser/lost_cred`,
    params: data,
  });
}

export function reactivateOrgUser(
  data: any,
): Observable<AxiosResponse> {
  const {user_id} = data;
  return axiosRequest({
    method: 'PATCH',
    url: `/orguser/reactivate/${user_id}`,
  }) as Observable<AxiosResponse>;
}
