import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OrgOption} from '../../interface';

// Define a type for the slice state
interface SelectReportOrgState {
  // better use this as lookup with LookupContext, than use redux selector
  org: OrgOption;
}

// Define the initial state using that type
const initialState: SelectReportOrgState = {
  org: {org_id: '', name: 'All'},
};

export const selectedReportOrgSlice = createSlice({
  name: 'SelectReportOrg',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearSelectReportOrgSlice() {
      return initialState;
    },
    setSelectReportOrg(state, action: PayloadAction<OrgOption>) {
      state.org = action.payload;
    },
  },
});

export const {clearSelectReportOrgSlice, setSelectReportOrg} =
  selectedReportOrgSlice.actions;

export default selectedReportOrgSlice.reducer;
