import {finalize} from 'rxjs';
import {setLogoutAndClearData} from './../../redux/features/authSlice';
import {
  MOCK_LOGIN,
  ACCESS_TOKEN_EXPIRES_AT,
  REFRESH_TOKEN_EXPIRES_AT,
  SESSION_EXPIRED,
  TIME_GAP,
} from './../../constants/index';
import {useEffect, useState} from 'react';
import isExpiresd from '../../utils/isExpires';
import store from '../../redux/store';
import {refreshToken} from '../../services';
import moment from 'moment';
import {useAppSelector} from '../../hook/useRedux';
import {selectIsAuthenticated} from '../../redux/selectors/authSlice';
import {getString, setString} from '../../utils/storageUtils';

function CheckExpires({children}: {children: JSX.Element}) {
  const [isRefreshCount, setIsRefreshCount] = useState<number>(0);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  useEffect(() => {
    if (!MOCK_LOGIN) {
      const accessTokenExpires = getString(ACCESS_TOKEN_EXPIRES_AT, '');
      if (!window.tokenTimeout && accessTokenExpires) {
        window.tokenTimeout = setTimeout(() => {
          if (isAuthenticated) {
            refreshToken()
              .pipe(
                finalize(() => {
                  setIsRefreshCount(isRefreshCount + 1);
                }),
              )
              .subscribe({
                next(d) {
                  setString(
                    ACCESS_TOKEN_EXPIRES_AT,
                    d.result[ACCESS_TOKEN_EXPIRES_AT],
                  );
                  setString(
                    REFRESH_TOKEN_EXPIRES_AT,
                    d.result[REFRESH_TOKEN_EXPIRES_AT],
                  );
                  window.tokenTimeout = undefined;
                  if (
                    isExpiresd(d.result[ACCESS_TOKEN_EXPIRES_AT]) ||
                    isExpiresd(d.result[REFRESH_TOKEN_EXPIRES_AT])
                  ) {
                    store.dispatch(setLogoutAndClearData());
                    sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
                  }
                },
                error() {
                  store.dispatch(setLogoutAndClearData());
                  sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
                  window.tokenTimeout = undefined;
                },
              });
          }
        }, moment.utc(accessTokenExpires).subtract(TIME_GAP, 'seconds').diff(moment.utc(), 'milliseconds'));
      }
    }
  }, [isRefreshCount, isAuthenticated]);
  useEffect(() => {
    return () => {
      clearTimeout(window.tokenTimeout);
    };
  }, []);
  return children;
}

export default CheckExpires;
