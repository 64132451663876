import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CurrentOrg} from '../../interface';

// Define a type for the slice state
interface CurrentOrganizationState {
  // better use this as lookup with LookupContext, than use redux selector
  currentOrganization: CurrentOrg;
}

// Define the initial state using that type
const initialState: CurrentOrganizationState = {
  currentOrganization: {
    contact_person_email: '',
    contact_person_name: '',
    created_at: '',
    created_by: '',
    deleted_at: '',
    deleted_by: '',
    did: '',
    email: '',
    id: '',
    is_active: false,
    is_additional_info_submitted: '',
    jwt_token: '',
    last_updated_at: '',
    name: '',
    org_address: {
      address1: '',
      address2: '',
      country: '',
      zip_code: '',
    },
    org_logo: '',
    privacy_policy_agreed: false,
    ver_key: '',
    wallet_id: '',
  },
};

export const currentOrganizationSlice = createSlice({
  name: 'Current_Organization',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearCurrentOrganizationSlice() {
      return initialState;
    },
    setCurrenOrganization(
      state,
      action: PayloadAction<{currentOrganization: CurrentOrg}>,
    ) {
      const {currentOrganization} = action.payload;
      state.currentOrganization = currentOrganization;
    },
  },
});

export const {clearCurrentOrganizationSlice, setCurrenOrganization} =
  currentOrganizationSlice.actions;

export default currentOrganizationSlice.reducer;
