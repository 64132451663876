import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_PREFIX, ORG_USER, PLATFORM_ADMIN } from '../../constants';
import { useAppSelector } from '../../hook/useRedux';
import { CustomUser } from '../../interface';
import { selectAuthUser, selectIsAuthenticated, selectIsInMaintenance } from '../../redux/selectors/authSlice';

function AuthRedirect({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authUser = useAppSelector(selectAuthUser) as CustomUser;
  const isInMaintenance = useAppSelector(selectIsInMaintenance);

  useEffect(() => {
    // If already login,redirect to dashboard page.
    if(isInMaintenance?.status) {
      navigate('/maintenance');
    } else {
      if (isAuthenticated) {
        if(authUser.role === PLATFORM_ADMIN) {
          navigate(`${ADMIN_PREFIX}/`);
        } 
      }
    }
  }, []);

  return children;
}

export default AuthRedirect;
