import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { map, Observable, of, tap } from 'rxjs';
import { WebFontItem } from '../../interface/fonts';
import { listAllWebFonts } from '../../services';

interface State {
  webFontItems: WebFontItem[];
  requestWebFontItems: () => Observable<Record<string, WebFontItem>>;
  getFontFaceKey: (fontName: string, fontWeight: string) => string;
  loadFontFamily: (fontName: string, fontAsset: string) => void;
}

const initialState: State = {
  webFontItems: [],
  requestWebFontItems: () => {
    throw new Error('requestWebFontItems Stub');
  },
  getFontFaceKey: () => {
    throw new Error('getFontFaceKey Stub');
  },
  loadFontFamily: () => {
    throw new Error('loadFontFamily Stub');
  },
};

export const fontWeightNameMapping: Record<string, string> = {
  '100': 'Thin',
  '200': 'Extra Light',
  '300': 'Light',
  '400': 'Normal',
  '500': 'Medium',
  '600': 'Semi Bold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Black',
};

export const getFontFamilyWeightVariants = (webFontItem: WebFontItem) => {
  return (
    webFontItem.variants.filter(
      (variant) => !!fontWeightNameMapping[variant]
    ) || Object.keys(fontWeightNameMapping)
  );
};

export const WebFontLoaderContext = createContext(initialState);

function WebFontLoaderProvider({ children }: PropsWithChildren) {
  const [googleWebFontItemsList, setGoogleWebFontItemsList] = useState<
    WebFontItem[]
  >([]);
  const [googleWebFontItemsDict, setGoogleWebFontItemsDict] = useState<
    Record<string, WebFontItem>
  >({});
  const [loadedWebFontItemsList, setLoadedWebFontItemsList] = useState<
    WebFontItem[]
  >([]);
  const [loadedWebFontItemsDict, setLoadedWebFontItemsDict] = useState<
    Record<string, WebFontItem>
  >({});

  const getFontFaceKey = useCallback((fontName: string, fontWeight: string) => {
    return JSON.stringify({
      family: fontName,
      ...(fontWeight ? { weight: fontWeight } : {}),
    });
  }, []);

  const requestWebFontItems = useCallback(() => {
    return Object.keys(googleWebFontItemsDict).length > 0
      ? of(googleWebFontItemsDict)
      : listAllWebFonts().pipe(
          map((res) =>
            res.items.reduce((dict, item) => {
              const regularIndex = item.variants.indexOf('regular');
              if (regularIndex >= 0) {
                item.variants[regularIndex] = '400';
                item.files['400'] = item.files['regular'];
              }
              const variants = getFontFamilyWeightVariants(item);
              dict[item.family] = {
                ...item,
                familyUrl: `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
                  item.family
                )}:wght@${variants.join(';')}&display=swap`,
              };
              return dict;
            }, {} as Record<string, WebFontItem>)
          ),
          tap((res) => {
            setGoogleWebFontItemsDict(res);
            setGoogleWebFontItemsList(Object.values(res));
          })
        );
  }, [googleWebFontItemsDict]);

  const loadFontFamily = useCallback(
    (fontName: string, fontAsset: string) => {
      if (!loadedWebFontItemsDict[fontName]) {
        const item = {
          family: fontName,
          variants: ['400'],
          files: {},
          familyUrl: fontAsset,
        };
        setLoadedWebFontItemsList((previousState) => [item, ...previousState]);
        setLoadedWebFontItemsDict((previousState) => ({
          [fontName]: item,
          ...previousState,
        }));
      }
    },
    [loadedWebFontItemsDict]
  );

  const webFontItems = useMemo(() => {
    return [
      ...loadedWebFontItemsList.filter(
        (item) => !googleWebFontItemsDict[item.family]
      ),
      ...googleWebFontItemsList,
    ];
  }, [googleWebFontItemsList, googleWebFontItemsDict, loadedWebFontItemsList]);

  return (
    <WebFontLoaderContext.Provider
      value={{
        webFontItems,
        requestWebFontItems,
        getFontFaceKey,
        loadFontFamily,
      }}
    >
      {children}
      {createPortal(
        <>
          {loadedWebFontItemsList.map((item, index) => (
            <link
              key={item.family + index}
              href={item.familyUrl}
              rel="stylesheet"
            />
          ))}
        </>,
        document.getElementById('webfontLoaderPortal') as HTMLStyleElement
      )}
    </WebFontLoaderContext.Provider>
  );
}

export default WebFontLoaderProvider;
