import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface RunOnState {
  label: string; value: string
}

// Define the initial state using that type
const initialState: RunOnState[] = [{label: '', value: ''}];

export const runOnSlice = createSlice({
  name: 'runOn',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearRunOnSlice() {
      return initialState;
    },
    setRunOn(state, action: PayloadAction<RunOnState[]>) {
      state = action.payload;
      return state;
    },
  },
});

export const {clearRunOnSlice, setRunOn} = runOnSlice.actions;

export default runOnSlice.reducer;
