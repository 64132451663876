import {AxiosResponse} from 'axios';
import {Observable} from 'rxjs';
import {
  BulkDeclineCredentialsRequest,
  BulkDeclineResponse,
  BulkDeclineVerificationRequest,
  BulkRevokeCredentialsRequest,
  CreateSchemaRequest,
  CreateSchemaResponse,
  CreateTemplateForm,
  CredentialOffer,
  CredentialsOverviewResonse,
  CredentialsResponse,
  DeclineCredentialRequest,
  DeclineVerificationsRequest,
  DeleteSchemaRequest,
  GetConnectionRequest,
  GetCredentialsOverviewRequest,
  IssueCredentialsRequest,
  IVerification,
  PaginationResponse,
  ProofRequest,
  PublicSchemaResponse,
  PublicCommonSchemaResponse,
  RevokeCredentialsRequest,
  RevokeCredentialsResponse,
  SchemaOverview,
  SchemaTemplate,
  Template,
  VerificationOverviewRes,
  InitiatedRequest,
  CredentialsInitiatedResponse,
  VerificationsInitiatedResponse,
  CreateSchemaFromTemplateApiRequest,
  CreateSchemaFromTemplateApiResponse,
  UpdateSchemaRequest,
  UpdateSchemaResponse
} from '../interface';
import {axiosRequest} from './http';
import { axiosInstance } from '../api/fetchData';
import _ from 'lodash';

export function getTemplates(
  data: GetConnectionRequest,
): Observable<PaginationResponse<Template>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/template/search`,
    params: data,
  }) as Observable<PaginationResponse<Template>>;
}

export function postRequestProof(
  data: ProofRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/verification/presentation`,
    data: data,
  }) as Observable<AxiosResponse>;
}

export function getSchema(id: string): Observable<SchemaTemplate> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/id/${id}`,
  }) as Observable<SchemaTemplate>;
}

export function getSchemaTemplates(
  data: GetConnectionRequest,
): Observable<PaginationResponse<SchemaTemplate>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/search`,
    params: data,
  }) as Observable<PaginationResponse<SchemaTemplate>>;
}

export function deleteSchemaTemplate(
  data: DeleteSchemaRequest,
): Observable<SchemaTemplate> {
  const {schema_id} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'DELETE',
    url: `/cred/schema/${schema_id}`,
  }) as Observable<SchemaTemplate>;
}

export function getSchemasCount(): Observable<SchemaOverview> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/overview`,
  }) as Observable<SchemaOverview>;
}

export function postSchema(
  data: CreateSchemaRequest,
): Observable<CreateSchemaResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/schema`,
    data: data,
  }) as Observable<CreateSchemaResponse>;
}

export function updateSchema(
  data: UpdateSchemaRequest,
): Observable<UpdateSchemaResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'PATCH',
    url: `/cred/schema/${data.schema_id}`,
    data: data.payload,
  }) as Observable<UpdateSchemaResponse>;
}

export function postCommonSchema(
  data: CreateSchemaFromTemplateApiRequest,
): Observable<CreateSchemaFromTemplateApiResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/schema/exists`,
    data: data,
  }) as Observable<CreateSchemaFromTemplateApiResponse>;
}

export function getVerificationOverview(data?: {
  dept_id?: string;
}): Observable<VerificationOverviewRes> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/overview`,
    headers: data,
  }) as Observable<VerificationOverviewRes>;
}

export function getVerifications(
  data: GetConnectionRequest,
): Observable<PaginationResponse<IVerification>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/search`,
    params: data,
  }) as Observable<PaginationResponse<IVerification>>;
}

export function getVerificationTemplateOverview(
  data: GetConnectionRequest,
): Observable<PaginationResponse<Template>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/template/overview`,
    params: data,
  }) as Observable<PaginationResponse<Template>>;
}

export function getVerificationTemplate(
  data: GetConnectionRequest,
): Observable<PaginationResponse<Template>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/template/search`,
    params: data,
  }) as Observable<PaginationResponse<Template>>;
}

export function postTemplate(
  data: CreateTemplateForm,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/verification/template`,
    data: data,
  }) as Observable<AxiosResponse>;
}

export function updateTemplate(
  data: CreateTemplateForm,
): Observable<AxiosResponse> {
  const {template_id} = data;
  const payload = _.omit(data, 'template_id')
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'PATCH',
    url: `/cred/verification/template/${template_id}`,
    data: payload,
  }) as Observable<AxiosResponse>;
}

export function deleteTemplate(data: {
  template_id: string;
}): Observable<AxiosResponse> {
  const {template_id} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'DELETE',
    url: `/cred/verification/template/${template_id}`,
  }) as Observable<AxiosResponse>;
}

export function getPublicSchemaTemplates(): Observable<PublicSchemaResponse[]> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/public`,
  }) as Observable<PublicSchemaResponse[]>;
}

export function getPublicCommonSchemaTemplates(): Observable<any> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/platform-standard-schemas?include_inactive=false`,
  }) as Observable<any>;
}

export function declineVerifications(
  data: DeclineVerificationsRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/verification/decline`,
    params: data,
  }) as Observable<AxiosResponse>;
}

export function verifyVerifications(
  data: DeclineVerificationsRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/verification/verify`,
    data: data,
  }) as Observable<AxiosResponse>;
}

export function bulkDeclineVerification(
  data: BulkDeclineVerificationRequest,
): Observable<BulkDeclineResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/verification/decline/bulk`,
    data: data,
  }) as Observable<BulkDeclineResponse>;
}

export function postOfferCredential(
  data: CredentialOffer,
): Observable<CredentialOffer> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/issuance/offer`,
    data: data,
  }) as Observable<CredentialOffer>;
}

export function getCredentialsOverview(
  data: GetCredentialsOverviewRequest,
): Observable<CredentialsOverviewResonse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/issuance/overview`,
    params: data,
  }) as Observable<CredentialsOverviewResonse>;
}

export function getCredentials(
  data: GetConnectionRequest,
): Observable<PaginationResponse<CredentialsResponse>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/issuance/search`,
    params: data,
  }) as Observable<PaginationResponse<CredentialsResponse>>;
}

export function postCredentialRevocation(
  data: RevokeCredentialsRequest,
): Observable<RevokeCredentialsResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/revocation`,
    data: data,
  }) as Observable<RevokeCredentialsResponse>;
}

export function postCredentialIssued(
  data: IssueCredentialsRequest,
): Observable<AxiosResponse> {
  const {credential_exchange_id, ...rest} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/issuance/${credential_exchange_id}`,
    data: rest.issueCredentialReq,
  }) as Observable<AxiosResponse>;
}

export function postBulkCredentialRevocation(
  data: BulkRevokeCredentialsRequest,
): Observable<RevokeCredentialsResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/revocation/bulk`,
    data: data,
  }) as Observable<RevokeCredentialsResponse>;
}

export function declineCredentials(
  data: DeclineCredentialRequest & {dept_id?: string},
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/issuance/decline`,
    params: data,
  }) as Observable<AxiosResponse>;
}

export function bulkDeclineCredentials(
  data: BulkDeclineCredentialsRequest,
): Observable<BulkDeclineResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/cred/issuance/decline/bulk`,
    data: data,
  }) as Observable<BulkDeclineResponse>;
}

export function getInitiatedCredentials(
  data: InitiatedRequest,
): Observable<PaginationResponse<CredentialsInitiatedResponse>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/initiated`,
    params: data,
  }) as Observable<PaginationResponse<CredentialsInitiatedResponse>>;
}

export function getInitiatedVerifications(
  data: InitiatedRequest,
): Observable<PaginationResponse<VerificationsInitiatedResponse>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/verification/initiated`,
    params: data,
  }) as Observable<PaginationResponse<VerificationsInitiatedResponse>>;
}

export function getAllSchemaTemplates(data: any) {
  return axiosInstance({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/cred/schema/search`,
    params: data,
  });
}


export function getPublicCommonSchemaTemplatesForPlatform(
  data: any,
): Observable<PaginationResponse<any>> {
  //For testing only. Remove when deploying
  // axiosInstance.defaults.headers.common['org_id'] = '894369c4-39b6-49b3-9eb4-803440499802';
  // delete axiosInstance.defaults.headers.common['x-pf-admin-secret-key'];
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `diceidd/cred/schema/search`,
    params: data
  }) as Observable<PaginationResponse<any>>;
}
