import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { from, map } from 'rxjs';
import { axiosInstance } from '../api/fetchData';
import { WITHCREDENTIALS } from '../constants';

export function wrapAxiosResToDataObservable<T>(
  res: Promise<AxiosResponse<T>>
) {
  return from(res).pipe(map((res) => res.data));
}

export function axiosRequest(requestConfig: AxiosRequestConfig) {
  return wrapAxiosResToDataObservable(axiosInstance.request(requestConfig));
}

export function axiosWihtoutExpiresRequest(requestConfig: AxiosRequestConfig) {
  requestConfig.withCredentials = requestConfig.withCredentials ?? WITHCREDENTIALS;
  return wrapAxiosResToDataObservable(axios.request(requestConfig));
}