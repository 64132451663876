import React, {useEffect, useContext, lazy, Suspense, LazyExoticComponent} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {ThemeProvider} from '@mui/material';
import 'simplebar-react/dist/simplebar.min.css';
import RequireAuth from './components/RequireAuth';
import theme from './theme';
import './style/style.scss';
import {useAppDispatch, useAppSelector} from './hook/useRedux';
import {selectIsAuthenticated} from './redux/selectors/authSlice';
import AuthRedirect from './components/AuthRedirect';
import Toast from './components/Toast';
import {selectSnackbarState} from './redux/selectors/snackbarSlice';
import {updateSnackbar} from './redux/features/snackbarSlice';
import {LookupContext} from './components/LookupContext';
import RequireOnboarded from './components/RequireOnboarded';
import RequirePlatformAuth from './components/RequirePlatformAuth';
import {setReportsDateRange} from './redux/features/reportsDateRangeSlice';
import {getPresetDateRange} from './utils/dateUtils';
import {TimePeriod} from './interface';
import CheckExpires from './components/CheckExpires';
import { lazyRetry } from './utils/loadUtils';
import Maintenance from './pages/Maintenance';

function createLazyLoadWrapper(
  displayedName: string,
  LazyComponent: LazyExoticComponent<() => JSX.Element>
) {
  const comp = () => {
    return (
      <Suspense>
        <LazyComponent />
      </Suspense>
    );
  };
  comp.displayedName = displayedName;
  return comp;
}

const Login = createLazyLoadWrapper('Login', lazy(() => lazyRetry(() => import('./pages/Login'), 'Login')));
const Dashboard = createLazyLoadWrapper('Dashboard', lazy(() => lazyRetry(() => import('./pages/Dashboard'), 'Dashboard')));
const Reports = createLazyLoadWrapper('Reports', lazy(() => lazyRetry(() => import('./pages/Reports'), 'Reports')));
const DepartmentsReports = createLazyLoadWrapper('DepartmentsReports', lazy(() => lazyRetry(() => import('./pages/Reports/DepartmentsReports'), 'DepartmentsReports')));
const Admin = createLazyLoadWrapper('Admin', lazy(() => lazyRetry(() => import('./pages/Admin'), 'Admin')));
const AdminDashboard = createLazyLoadWrapper('AdminDashboard', lazy(() => lazyRetry(() => import('./pages/Admin/Dashboard'), 'AdminDashboard')));
const AdminExecutive = createLazyLoadWrapper('AdminExecutive', lazy(() => lazyRetry(() => import('./pages/Admin/Executive'), 'AdminExecutive')));
const AdminOrg = createLazyLoadWrapper('AdminOrg', lazy(() => lazyRetry(() => import('./pages/Admin/Organization'), 'AdminOrg')));
const AdminOrgsOverview = createLazyLoadWrapper('AdminOrgsOverview', lazy(() => lazyRetry(() => import('./pages/Admin/Organization/OrgsOverview'), 'AdminOrgsOverview')));
const AdminOrgDetails = createLazyLoadWrapper('AdminOrgDetails', lazy(() => lazyRetry(() => import('./pages/Admin/Organization/OrgDetails'), 'AdminOrgDetails')));
const AdminOrgAdminDetails = createLazyLoadWrapper('AdminOrgAdminDetails', lazy(() => lazyRetry(() => import('./pages/Admin/Organization/OrgAdminDetails'), 'AdminOrgAdminDetails')));
const AdminOrgAdminEdit = createLazyLoadWrapper('AdminOrgAdminEdit', lazy(() => lazyRetry(() => import('./pages/Admin/Organization/OrgAdminEdit'), 'AdminOrgAdminEdit')));
const AdminOrgInviteAdmin = createLazyLoadWrapper('AdminOrgInviteAdmin', lazy(() => lazyRetry(() => import('./pages/Admin/Organization/InviteOrgAdmin'), 'AdminOrgInviteAdmin')));
const AdminRoles = createLazyLoadWrapper('AdminRoles', lazy(() => lazyRetry(() => import('./pages/Admin/Roles'), 'AdminRoles')));
const AdminRolesOverview = createLazyLoadWrapper('AdminRolesOverview', lazy(() => lazyRetry(() => import('./pages/Admin/Roles/RolesOverview'), 'AdminRolesOverview')));
const AdminRoleDetails = createLazyLoadWrapper('AdminRoleDetails', lazy(() => lazyRetry(() => import('./pages/Admin/Roles/RoleDetails'), 'AdminRoleDetails')));
const AdminAddRole = createLazyLoadWrapper('AdminAddRole', lazy(() => lazyRetry(() => import('./pages/Admin/Roles/AddRole'), 'AdminAddRole')));
const KycOverview = createLazyLoadWrapper('KycOverview', lazy(() => lazyRetry(() => import('./pages/Admin/KycOverview'), 'KycOverview')));
const OrganizationUserReports = createLazyLoadWrapper('OrganizationUserReports', lazy(() => lazyRetry(() => import('./pages/Reports/OrganizationUserReports'), 'OrganizationUserReports')));
const ConnectionsInvitedReports = createLazyLoadWrapper('ConnectionsInvitedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CustomersInvitedReports'), 'ConnectionsInvitedReports')));
const ConnectionsErrorReports = createLazyLoadWrapper('ConnectionsErrorReports', lazy(() => lazyRetry(() => import('./pages/Reports/CustomerErrorsReports'), 'ConnectionsErrorReports')));
const ConnectionsReceivedReports = createLazyLoadWrapper('ConnectionsReceivedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CustomersRespondedReports'), 'ConnectionsReceivedReports')));
const ConnectionsAcceptedReports = createLazyLoadWrapper('ConnectionsAcceptedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CustomersOnboardedReports'), 'ConnectionsAcceptedReports')));
const CredentialsOfferedReports = createLazyLoadWrapper('CredentialsOfferedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CredentialsOfferedReports'), 'CredentialsOfferedReports')));
const CredentialsRequestedReports = createLazyLoadWrapper('CredentialsRequestedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CredentialsRequestedReports'), 'CredentialsRequestedReports')));
const CredentialsIssuedReports = createLazyLoadWrapper('CredentialsIssuedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CredentialsIssuedReports'), 'CredentialsIssuedReports')));
const CredentialsRevokedReports = createLazyLoadWrapper('CredentialsRevokedReports', lazy(() => lazyRetry(() => import('./pages/Reports/CredentialsRevokedReports'), 'CredentialsRevokedReports')));
const SchemasReports = createLazyLoadWrapper('SchemasReports', lazy(() => lazyRetry(() => import('./pages/Reports/SchemasReports'), 'SchemasReports')));
const VerificationTemplatesReports = createLazyLoadWrapper('VerificationTemplatesReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerificationTemplatesReports'), 'VerificationTemplatesReports')));
const VerificationRequestSentReports = createLazyLoadWrapper('VerificationRequestSentReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerificationRequestSentReports'), 'VerificationRequestSentReports')));
const VerificationRequestPendingReports = createLazyLoadWrapper('VerificationRequestPendingReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerificationRequestPendingReports'), 'VerificationRequestPendingReports')));
const VerifiedSuccessReports = createLazyLoadWrapper('VerifiedSuccessReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerifiedSuccessReports'), 'VerifiedSuccessReports')));
const VerifiedFailureReports = createLazyLoadWrapper('VerifiedFailureReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerifiedFailureReports'), 'VerifiedFailureReports')));
const VerifiedSuccessConnectionLessReports = createLazyLoadWrapper('VerifiedSuccessConnectionLessReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerifiedSuccessConnectionLessReports'), 'VerifiedSuccessConnectionLessReports')));
const VerifiedFailureConnectionLessReports = createLazyLoadWrapper('VerifiedFailureConnectionLessReports', lazy(() => lazyRetry(() => import('./pages/Reports/VerifiedFailureConnectionLessReports'), 'VerifiedFailureConnectionLessReports')));
const ReportsOverview = createLazyLoadWrapper('ReportsOverview', lazy(() => lazyRetry(() => import('./pages/Reports/ReportsOverview'), 'ReportsOverview')));
const OrganizationsReports = createLazyLoadWrapper('OrganizationsReports', lazy(() => lazyRetry(() => import('./pages/Reports/OrganizationsReports'), 'OrganizationsReports')));
const AdminSchema = createLazyLoadWrapper('AdminSchema', lazy(() => lazyRetry(() => import('./pages/Admin/AdminSchema'), 'AdminSchema')));
const SubscriptionsAndPlans = createLazyLoadWrapper('SubscriptionsAndPlans', lazy(() => lazyRetry(() => import('./pages/SubscriptionsAndPlans'), 'SubscriptionsAndPlans')));
const OrganizationRequests = createLazyLoadWrapper('OrganizationRequests', lazy(() => lazyRetry(() => import('./pages/OrganizationRequests'), 'OrganizationRequests')));

function App() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const snackbarState = useAppSelector(selectSnackbarState);

  const {refreshAll} = useContext(LookupContext);

  useEffect(() => {
    if (isAuthenticated) {
      refreshAll();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(
      setReportsDateRange({
        dateRange: getPresetDateRange(TimePeriod.TillDate),
        timePeriod: TimePeriod.TillDate,
      }),
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Toast
        isShow={snackbarState.isShow}
        color={snackbarState?.color}
        message={snackbarState?.message ?? ''}
        dismiss={() => {
          dispatch(
            updateSnackbar({
              isShow: false,
            }),
          );
        }}
      />
      <Router>
        <Routes>
          <Route
            path='/login'
            element={
              <AuthRedirect>
                <Login />
              </AuthRedirect>
            }
          />
          
          <Route
            path='/'
            element={
              <RequireAuth>
                  <CheckExpires>
                    <Dashboard />
                  </CheckExpires>
              </RequireAuth>
            }
          >
            {/* <Route index element={<Navigate replace to='dashboard' />} />
            <Route path='dashboard' element={<Reports />}></Route> */}
          </Route>
          
          <Route
            path='admin'
            element={
              <RequirePlatformAuth>
                <CheckExpires>
                  <Admin />
                </CheckExpires>
              </RequirePlatformAuth>
            }
          >
            <Route index element={<Navigate replace to='dashboard' />}></Route>
            <Route path='dashboard' element={<AdminExecutive />} />
            <Route path='schemas' element={<AdminSchema />} />
            <Route path='organization' element={<AdminOrg />}>
              <Route index element={<AdminOrgsOverview />} />
              <Route path=':id' element={<AdminOrgDetails />} />
              <Route
                path=':id/view/:userId'
                element={<AdminOrgAdminDetails />}
              />
              <Route path=':id/edit/:userId' element={<AdminOrgAdminEdit />} />
              <Route path=':id/invite' element={<AdminOrgInviteAdmin />} />
            </Route>
            <Route path='subscriptions-plans' element={<SubscriptionsAndPlans />} />
            <Route path='roles' element={<AdminRoles />}>
              <Route index element={<AdminRolesOverview />} />
              <Route path=':id' element={<AdminRoleDetails />} />
              <Route path='create' element={<AdminAddRole />} />
            </Route>
            <Route path='organization-requests' element={<OrganizationRequests />} />
            <Route path='reports' element={<Reports />}>
              <Route index element={<ReportsOverview />} />
              <Route path='organizations' element={<OrganizationsReports />} />
              <Route path='departments' element={<DepartmentsReports />} />
              <Route
                path='organization-users'
                element={<OrganizationUserReports />}
              />
              <Route
                path='customers-invited'
                element={<ConnectionsInvitedReports />}
              />
              <Route
                path='customers-request-received'
                element={<ConnectionsReceivedReports />}
              />
              <Route
                path='customers-request-accepted'
                element={<ConnectionsAcceptedReports />}
              />
              <Route
                path='customer-errors'
                element={<ConnectionsErrorReports />}
              />
              <Route
                path='credentials-offered'
                element={<CredentialsOfferedReports />}
              />
              <Route
                path='credentials-requested'
                element={<CredentialsRequestedReports />}
              />
              <Route
                path='credentials-issued'
                element={<CredentialsIssuedReports />}
              />
              <Route
                path='credentials-revoked'
                element={<CredentialsRevokedReports />}
              />
              <Route path='schemas' element={<SchemasReports />} />
              <Route
                path='verification-templates'
                element={<VerificationTemplatesReports />}
              />
              <Route
                path='verification-request-sent'
                element={<VerificationRequestSentReports />}
              />
              <Route
                path='verification-request-pending'
                element={<VerificationRequestPendingReports />}
              />
              <Route
                path='verified-success'
                element={<VerifiedSuccessReports />}
              />
              <Route
                path='verified-failure'
                element={<VerifiedFailureReports />}
              />
              <Route
                path='verified-success-customer-less'
                element={<VerifiedSuccessConnectionLessReports />}
              />
              <Route
                path='verified-failure-customer-less'
                element={<VerifiedFailureConnectionLessReports />}
              />
            </Route>
            <Route path='kyc' element={<KycOverview />} />
            <Route path='reports' element={<Reports />} />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route path='maintenance' element={<Maintenance />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
