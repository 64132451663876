import { ResponseDefaultError } from '../interface';

export function getResponseError(data?: {
  status?: number;
  error?: ResponseDefaultError;
}): string {
  const { status, error } = data ?? {};
  return (
    error?.message ??
    (status === undefined
      ? 'Request failed'
      : `Request failed with status code ${status}`)
  );
}
