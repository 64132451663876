import {Notification} from '../interface';
import _ from 'lodash';
import moment from 'moment';
import {EXPIRED} from '../constants';

const filterNotifications = (ns: Notification[]) => {
  return _.filter(ns, (n) => {
    if (n.read_status) {
      return true;
    }
    return moment
      .utc(n.published_at, 'DD/MM/YYYY hh:mm:ss A')
      .local()
      .isBetween(moment.utc().subtract(EXPIRED), moment.utc());
  });
};

export default filterNotifications;
