import crypto from "crypto-js";
import {nanoid} from "nanoid";
import {Buffer} from "buffer";


export function getCodeChallengeAndVerifier() {
    function base64URLEncode(str: Buffer) {
        return str.toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    }
    const code_verifier = base64URLEncode(Buffer.from(nanoid(48)));
    function sha256(str: string) {
        return crypto.SHA256(str).toString(crypto.enc.Base64url);
    }
    // PKCE standard Code challenge
    const code_challenge = sha256(code_verifier);
    // window.sessionStorage.setItem("code_verifier", code_verifier);
    window.localStorage.setItem('code_verifier', code_verifier);
    window.localStorage.setItem('code_challenge', code_challenge);
    return { code_challenge, code_verifier };
}