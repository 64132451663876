import React, {PropsWithChildren, createContext, useMemo} from 'react';
import {finalize} from 'rxjs';
import {ALL_PAGE_SIZE, PLATFORM_ADMIN} from '../../constants';
import {useAppDispatch, useAppSelector} from '../../hook/useRedux';
import useSubscription from '../../hook/useSubscription';
import {Role} from '../../interface';
import {setRoles} from '../../redux/features/platformSlice';
import {selectAuthUser} from '../../redux/selectors/authSlice';
import {
  selectPlatformRoleDict,
  selectPlatformRoleIds,
  selectPlatformRoleNameDict,
  selectPlatformRoles,
} from '../../redux/selectors/platformSlice';
import {getPlatformRoles} from '../../services';
import _ from 'lodash';

interface LookupItem<T> {
  ids: string[];
  items: T[];
  dict: Record<string, T>;
  nameDict: Record<string, T>;
  refresh: () => void;
}

export interface LookupContextState {
  roles: LookupItem<Role>;
  refreshAll: () => void;
}

const createInitialLookupItem = () => {
  return {
    ids: [],
    items: [],
    dict: {},
    nameDict: {},
    refresh: () => {
      throw 'Refresh Roles Lookup Stub';
    },
  };
};

export const LookupContext = createContext<LookupContextState>({
  roles: createInitialLookupItem(),
  refreshAll: () => {
    throw 'Refresh All Lookup Stub';
  },
});

export const LookupProvider = ({children}: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector(selectAuthUser);
  const roleIds = useAppSelector(selectPlatformRoleIds);
  const roles = useAppSelector(selectPlatformRoles);
  const roleDict = useAppSelector(selectPlatformRoleDict);
  const roleNameDict = useAppSelector(selectPlatformRoleNameDict);

  const rolesSubscription = useSubscription();

  const value = useMemo<LookupContextState>(() => {
    const refreshRoles = () => {
      rolesSubscription.unsubscribe();
      rolesSubscription.subscription.current = getPlatformRoles({ page_size: ALL_PAGE_SIZE })
        .pipe(
          finalize(() => {
            rolesSubscription.subscription.current = null;
          }),
        )
        .subscribe({
          next(res) {
            if(res.result) {
              const filteredRoles = res.result.data.filter((role)=> role.name !== 'DICE_ORG_USER_COMMON_ROLE')
              dispatch(setRoles({roles: filteredRoles}));
            }
          },
          error(err) {
            console.log(err);
          }
        })
    };

    const refreshAll = () => {
      refreshRoles();
    };

    return {
      roles: {
        ids: roleIds,
        items: roles,
        dict: roleDict,
        nameDict: roleNameDict,
        refresh: refreshRoles,
      },
      refreshAll,
      refreshRoles,
    };
  }, [dispatch, rolesSubscription, roleIds, roles, roleDict, roleNameDict]);

  return (
    <LookupContext.Provider value={value}>{children}</LookupContext.Provider>
  );
};
