export const CONNECTICONS = [
  'Connection Invited',
  'Connection Requested',
  'Connection Responded',
];

export const CHIPLIMIT = 2;
export const ITEMLIMIT = 2;

export const DURATION = 5000;

export const ORGADMIN = 'ORG_ADMIN';
export const OBSERVER = 'OBSERVER';
export const VERIFIER = 'VERIFIER';

export const DEFAULTDEPARTMENT = 'Default Department';

export const DISABLED = 'Disabled';

export const FILTER_CHIP_DATE_FORMAT = 'DD-MMM-YYYY';
export const DISPLAY_DATE_FORMAT = 'DD-MMM-YYYY';
export const DISPLAY_TIME_FORMAT = 'DD-MMM-YYYY HH:mm:ss';

export const DATEFORMAT = 'HH:mm:ss, DD MMMM yyyy';
export const NULL = 'null';
export const NEWDATEFORMAT = 'DD MMMM yyyy, HH:mm:ss';

export const TYPE_TEXTFIELD = ['text', 'number', 'decimal', 'email'];
export const TYPE_CHECKBOX = ['boolean'];
export const TYPE_DATEPICKER = ['date'];
export const TYPE_TIMEPICKER = ['datetime'];
export const TYPE_PHONE_NUMBER = ['phone'];
export const TYPE_FUTURE_DATE = ['future date'];
export const TYPE_PAST_DATE = ['past date'];

export const INTEGER = 'number';
export const NUMBER = ['number', 'decimal'];

export const EMAIL_PATTERN = 'email';
export const BOOLEAN_PATTERN = 'boolean';
export const PHONE_NUMBER_PATTERN = 'phone';

export const NUMERIC_INPUT_TYPE = 'numeric';
export const TEXT_INPUT_TYPE = 'text';

export const INVALID_DATE = 'Invalid date';

export const DATA_TYPE_LIST = ["text", "number", "decimal", "email", "boolean", "date", "datetime", "phone", "future date", "past date"];

export const MAX_PAGE_SIZE = 10;

export enum SCHEMA_SELECTOR {
  ID = 'Schema ID',
  Public = 'Public Schemas',
  Your = 'Your Schemas'
}

export const PREDICATE_TYPES = ['>', '<=', '>=', '<'];

export const LOADINGTIME = 2000;
export const MAX_LOGO_FILE_SIZE = 2 * 1024 * 1024;

export const VIEW = 'View';
export const DELETE = 'Delete';
export const EDIT = 'Edit';
export const ADD = 'Add';
export const VERIFY = 'Verify';

export const MAX_EXPORT_PAGE_SIZE = 500;

export const VERSION_REGEXP_PATTREN = /^(?:(\d+)\.){1,2}(\*|\d+)$/;
export const NAME_REGEXP_PATTERN = /^([a-zA-Z0-9 _-]+)$/;

export const DOCUMENT_TYPES = [
  'KYC Document Type 1',
  'KYC Document Type 2',
  'KYC Document Type 3',
];

export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
export const PROCESSING = 'Processing';

export const ADMIN_PREFIX = '/admin';

export const READ = 'Read';
export const WRITE = 'Write';

export const EDITED_ADMIN = 'edited_admin';
export const DELETED_ADMIN = 'deleted_admin';
export const INVITED_ADMIN = 'invited_admin';
export const DEACTIVATE_ADMIN = 'deactivate_admin';
export const CREATED_ROLE = 'created_role';
export const UPDATED_ROLE = 'updated_role';
export const DELETED_ROLE = 'deleted_role';
export const SUCCESS = 'Success';

const REACT_APP_PLATFORM_ADMIN_ID = process.env.REACT_APP_PLATFORM_ADMIN_ID;
export const PLATFORMADMINID = REACT_APP_PLATFORM_ADMIN_ID;

export const EMAILREGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const URIREGEXP = /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/;

export const KYCSTATUS = ['Pending', 'Successful', 'Failed'];

export const REPORTSTYPE = [
  'Organizations',
  'Transactions',
];

export const ORG_REPORTSTYPE = [
  'Organization Users',
  'Departments',
  'Customers',
  'Credentials',
  'Schemas',
  'Verifications',
  'Verification Templates',
];

export const NOTIFICATIONS = 'notifications';
export const READ_STATUS = 'read_status';
export const UNREAD = 'new';
export const EXPIRED = 48 * 1000 * 60 * 60;
export const JUST_NOW = 'Just now';
export const RESET = 1000 * 60;

export const MSG_ORDER = [
  {label: 'Read Status', value: 'read_status'},
  {label: 'Time', value: 'received_at'},
];

export const ALL = 'All';

const REACT_APP_MOCK_LOGIN = JSON.parse(
  process.env.REACT_APP_MOCK_LOGIN ?? 'false',
) as boolean;

export const MOCK_LOGIN = REACT_APP_MOCK_LOGIN;

export const PLATFORM_ADMIN = 'PLATFORM_ADMIN';
export const ORG_USER = 'ORG_USER';

export const ALL_PAGE_SIZE = 0;

export const WITHCREDENTIALS =
  process.env.REACT_APP_WITHCREDENTIALS === 'true' ? true : false;

export const MOCK_LOGIN_STORAGE = 'MOCK_LOGIN_STORAGE';

export const DATE_FORMAT_SHORT = 'DD MMM YYYY';

export const ENABLE_NOTIFICATION =
  process.env.REACT_APP_ENABLE_NOTIFICATION === 'false' ? false : true;

export const RUN_ON_REQUIRED_SCHEMA = [
  'EMAIL_TEMPLATE_TYPE_CUSTOMER_CREDENTIAL_ISSUE',
  'EMAIL_TEMPLATE_TYPE_CUSTOMER_CREDENTIAL_OFFER',
];

export const ORG_ADMIN = 'ORG ADMIN';

export const DELETED_CERTIFICATE_TEMPLATES = 'DELETED_CERTIFICATE_TEMPLATES';
export const CREATED_CERTIFICATE_TEMPLATE = 'Certificate Template created successfully.';
export const UPDATED_CERTIFICATE_TEMPLATE = 'Certificate Template updated successfully.';
export const SESSION_EXPIRED = 'Session Expired';

export const ASYNC_MESSAGE = 'Process has been initiated. Customer will receive an invitation.';
export const ASYNC_MESSAGE_MULTIPLE = 'Process has been initiated. Customers will receive an invitaion. Once the it is completed, you will receive an email with statistics.';
export const ASYNC_MESSAGE_ONBOARD = 'Onboarding process is initiated. You will receive an email soon. Please contact support@diceid.com for any support.';
export const CREDENTIAL_OFFERED = 'Process has been initiated. Customer will receive a Credential Offer on Mobile Wallet.';
export const REQUEST_PROOF = 'Process has been initiated. Customer will receive a request to submit the proof on Mobile Wallet.';
export const PLAN_SUCCESS = 'Plan created successfully.';

export const INVITE_ORG_USER_SUCCESS = 'Process has been initiated. Your colleague will receive an invitation through email.';

export const CRED_URL = 'Cred_URL';
export const DICE_DISPLAY_NAME = 'dice_display_name';

export const ACCESS_TOKEN_EXPIRES_AT = 'access_token_expires_at';
export const REFRESH_TOKEN_EXPIRES_AT = 'refresh_token_expires_at';

export const TIME_GAP = 10;
export const LOADING_TIME_GAP = 2000;

export const WHATSAPP =
  process.env.REACT_APP_WHATSAPP === 'true' ? true : false;

export const IS_PROD_ENV =
  process.env.REACT_APP_IS_PROD_ENV === 'true' ? true : false;

export const SCHEMA_NAME_MAX_LENGTH = parseInt(process.env.REACT_APP_SCHEMA_NAME_MAX_LENGTH || '0')

export const CERTIFICATE_TEMPLATE_IMAGE_JPEG_SIZE = Number(process.env.REACT_APP_CERTIFICATE_TEMPLATE_IMAGE_JPEG_SIZE) || 200;

export const CERTIFICATE_TEMPLATE_IMAGE_SIZE = Number(process.env.REACT_APP_CERTIFICATE_TEMPLATE_IMAGE_SIZE) || 1.5;

export const TOKEN_MAXLENGTH = Number(process.env.REACT_APP_TOKEN_MAXLENGTH) || 0;

export const TILL_DATE_START = process.env.REACT_APP_TILL_DATE_START || "01-Aug-2022";
