import React, {
  createContext,
  PropsWithChildren,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { finalize, mergeMap, Observable, of, Subject, tap } from 'rxjs';
import { ORG_USER } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hook/useRedux';
import {
  OrganizationPreview,
  OrgUser,
  SetUserFavoriteOrganizationRequest,
} from '../../interface';
import { setLogin } from '../../redux/features/authSlice';
import {
  setIsLoadingUserOrganizationList,
  setUserFavoriteOrganization,
  setUserOrganizationList,
} from '../../redux/features/organizationSlice';
import {
  selectIsLoadingUserOrganizationList,
  selectUserOrganizationList,
} from '../../redux/selectors/organizationSlice';
import {
  getUserOrganizationList,
  setUserFavOrganization,
} from '../../services';

interface State {
  orgList: OrganizationPreview[] | undefined;
  pullUserOrgList: (userId: string) => Observable<OrganizationPreview[]>;
  isPulling: boolean;
  changeFavoriteOrg: (
    data: SetUserFavoriteOrganizationRequest
  ) => Observable<OrgUser>;
}

const initialState: State = {
  orgList: undefined,
  pullUserOrgList: () => {
    throw new Error('setOrgDetails Stub');
  },
  isPulling: false,
  changeFavoriteOrg: () => {
    throw new Error('setOrgDetails Stub');
  },
};

export const UserOrganizationListContext = createContext(initialState);

function UserOrganizationListProvider({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();

  const orgList = useAppSelector(selectUserOrganizationList);
  const subject = useRef(new Subject<OrganizationPreview[]>());
  const isLoading = useAppSelector(selectIsLoadingUserOrganizationList);

  const orgListRef = useRef<OrganizationPreview[] | undefined>(orgList);
  orgListRef.current = orgList;
  const loadedRef = useRef<string | undefined>();
  const isLoadingRef = useRef<string | undefined>();
  isLoadingRef.current = isLoading ? isLoadingRef.current : undefined;

  useEffect(() => {
    if (orgList) {
      subject.current.next(orgList);
    }
  }, [orgList]);

  const setUserOrgList = useCallback(
    (val: OrganizationPreview[]) => {
      dispatch(
        setUserOrganizationList({
          orgList: val,
        })
      );
    },
    [dispatch]
  );

  const pullUserOrgList = useCallback(
    (userId: string) => {
      if (orgListRef.current && loadedRef.current === userId) {
        return of(orgListRef.current);
      } else if (isLoadingRef.current && isLoadingRef.current === userId) {
        return subject.current.asObservable();
      } else {
        return of(0).pipe(
          tap(() => {
            loadedRef.current = undefined;
            isLoadingRef.current = userId;
            dispatch(
              setIsLoadingUserOrganizationList({
                loading: true,
              })
            );
          }),
          mergeMap(() =>
            getUserOrganizationList().pipe(
              tap((res) => {
                if (userId === isLoadingRef.current) {
                  setUserOrgList(res);
                }
              })
            )
          ),
          tap(() => {
            loadedRef.current = userId;
          }),
          finalize(() =>
            dispatch(
              setIsLoadingUserOrganizationList({
                loading: false,
              })
            )
          )
        );
      }
    },
    [orgList, isLoadingRef, subject]
  );

  const changeFavoriteOrg = useCallback(
    (data: SetUserFavoriteOrganizationRequest) => {
      return setUserFavOrganization(data).pipe(
        tap((res) => {
          dispatch(
            setLogin({
              user: {...res, role: ORG_USER},
            })
          );
          dispatch(
            setUserFavoriteOrganization({
              orgId: data.new_fav_org_id,
            })
          );
        })
      );
    },
    [dispatch]
  );

  return (
    <UserOrganizationListContext.Provider
      value={{
        orgList,
        pullUserOrgList,
        isPulling: isLoading,
        changeFavoriteOrg,
      }}
    >
      {children}
    </UserOrganizationListContext.Provider>
  );
}

export default UserOrganizationListProvider;
