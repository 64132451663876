import {AxiosResponse} from 'axios';
import {Observable} from 'rxjs';
import {
  ApproveBulkRequest,
  BulkDeclineConnectionsRequest,
  BulkDeclineResponse,
  Connection,
  ConnectionInviteUserRequest,
  ConnectionInviteUserResponse,
  ConnectionOverviewResponse,
  ConnectionsBulkDeleteRequest,
  ConnectionsBulkDeleteResponse,
  DeclineConnectionsRequest,
  GetConnectionOverviewRequest,
  GetConnectionRequest,
  PaginationResponse,
  SampleFileRequest,
  BulkInviteUsersRequest,
  BulkInviteSampleFileRequest,
  ConnectionInitiatedRequest,
  ConnectionInitiatedResponse,
  BulkUploadHistoryRequest,
  BulkUploadHistoryResponse
} from '../interface';
import {axiosRequest} from './http';
import _ from 'lodash';
import { axiosInstance } from '../api/fetchData';

export function getConnectionOverview(
  data: GetConnectionOverviewRequest,
): Observable<ConnectionOverviewResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/connection/overview`,
    params: data,
  }) as Observable<ConnectionOverviewResponse>;
}

export function getConnection(
  data: GetConnectionRequest,
): Observable<PaginationResponse<Connection>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/connection/search`,
    params: data,
  }) as Observable<PaginationResponse<Connection>>;
}

export function inviteOrgUser(
  data: ConnectionInviteUserRequest,
): Observable<ConnectionInviteUserResponse> {
  const {email, sms, whatsApp, ...rest} = data;
  if (email?.invitee_email && _.includes(data.invite_modes, 'email')) {
    _.set(rest, 'email', email);
  }
  if (sms?.invitee_phoneNo && _.includes(data.invite_modes, 'sms')) {
    _.set(rest, 'sms', sms);
  }
  if (
    whatsApp?.invitee_whatsAppNo &&
    _.includes(data.invite_modes, 'whatsApp')
  ) {
    _.set(rest, 'whatsApp', whatsApp);
  }
  return axiosRequest({
    baseURL: process.env.REACT_APP_CREDENTIALS_BATCH_PROCESSOR_BASE_URL,
    method: 'POST',
    url: `/cred/issuance`,
    data: rest,
  }) as Observable<ConnectionInviteUserResponse>;
}

export function approve(data: {
  connection_id: string;
}): Observable<AxiosResponse> {
  const {connection_id} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/connection/acceptance/${connection_id}`,
  }) as Observable<AxiosResponse>;
}

export function approveBulk(
  data: ApproveBulkRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/connection/accept/bulk`,
    data: data,
  }) as Observable<AxiosResponse>;
}

export function connectionDelete(data: {
  connection_id: string;
}): Observable<Connection> {
  const {connection_id} = data;
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'DELETE',
    url: `/connection/${connection_id}`,
  }) as Observable<Connection>;
}

export function connectionsBulkDelete(
  data: ConnectionsBulkDeleteRequest,
): Observable<ConnectionsBulkDeleteResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'DELETE',
    url: `/connection/bulk`,
    data: data,
  }) as Observable<ConnectionsBulkDeleteResponse>;
}

export function declineConnections(
  data: DeclineConnectionsRequest,
): Observable<AxiosResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/connection/decline`,
    params: data,
  }) as Observable<AxiosResponse>;
}

export function bulkDeclineConnections(
  data: BulkDeclineConnectionsRequest,
): Observable<BulkDeclineResponse> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/connection/decline/bulk`,
    data: data,
  }) as Observable<BulkDeclineResponse>;
}

export function getSampleFile(data: SampleFileRequest) {
  return axiosInstance({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/connection/download-sample-file`,
    params: data,
    headers: {
      Accept: 'application/*',
    },
    responseType: 'blob'
  });
}

export function bulkInviteUsers(
  req: BulkInviteUsersRequest,
): Observable<AxiosResponse> {
  const {file, data, ...rest} = req;
  const formData = new FormData();
  formData.set('batch_csv', file);
  formData.set('data', data);
  return axiosRequest({
    baseURL: process.env.REACT_APP_CREDENTIALS_BATCH_PROCESSOR_BASE_URL,
    method: 'POST',
    url: `/cred/issuance/batch`,
    data: formData,
    params: rest,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }) as Observable<AxiosResponse>;
}

export function getBulkInviteSampleFile(data: BulkInviteSampleFileRequest) {
  return axiosInstance({
    baseURL: process.env.REACT_APP_CREDENTIALS_BATCH_PROCESSOR_BASE_URL,
    method: 'GET',
    url: `/cred/issuance/batch/sample/file`,
    params: data,
    headers: {
      Accept: 'application/*',
    },
    responseType: 'blob'
  });
}

export function inviteOrgUserWithoutTemplate(
  data: ConnectionInviteUserRequest,
): Observable<ConnectionInviteUserResponse> {
  const {email, sms, whatsApp, ...rest} = data;
  if (email?.invitee_email && _.includes(data.invite_modes, 'email')) {
    _.set(rest, 'email', email);
  }
  if (sms?.invitee_phoneNo && _.includes(data.invite_modes, 'sms')) {
    _.set(rest, 'sms', sms);
  }
  if (
    whatsApp?.invitee_whatsAppNo &&
    _.includes(data.invite_modes, 'whatsApp')
  ) {
    _.set(rest, 'whatsApp', whatsApp);
  }
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'POST',
    url: `/connection/invitation`,
    data: rest,
  }) as Observable<ConnectionInviteUserResponse>;
}

export function getConnectionInitiated(
  data: ConnectionInitiatedRequest,
): Observable<PaginationResponse<ConnectionInitiatedResponse>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CONTROLLER_API_BASE_URL,
    method: 'GET',
    url: `/connection/initiated`,
    params: data,
  }) as Observable<PaginationResponse<ConnectionInitiatedResponse>>;
}

export function getBulkUploadHistory(
  data: BulkUploadHistoryRequest,
): Observable<PaginationResponse<BulkUploadHistoryResponse>> {
  return axiosRequest({
    baseURL: process.env.REACT_APP_CREDENTIALS_BATCH_PROCESSOR_BASE_URL,
    method: 'GET',
    url: `/cred/issuance/batch`,
    params: data,
  }) as Observable<PaginationResponse<BulkUploadHistoryResponse>>;
}

export function downloadLogs(data: any) {
  return axiosInstance({
    baseURL: process.env.REACT_APP_CREDENTIALS_BATCH_PROCESSOR_BASE_URL,
    method: 'GET',
    url: `/cred/issuance/batch/${data._id}/csv`,
    params: data.dept_id ? {
      org_id: data.org_id,
      invoked_by: data.invoked_by,
      dept_id: data.dept_id
    } : {
      org_id: data.org_id,
      invoked_by: data.invoked_by
    },
    headers: {
      Accept: 'application/*',
    },
    responseType: 'blob'
  });
}
