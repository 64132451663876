import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import publicImage from '../../../../utils/publishImage';
import {selectAuthUser} from '../../../../redux/selectors/authSlice';
import {useAppDispatch, useAppSelector} from '../../../../hook/useRedux';
import {OrgUser} from '../../../../interface';
import theme from '../../../../theme';
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import {setLogoutAndClearData} from '../../../../redux/features/authSlice';

function MaintenanceHeader() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector(selectAuthUser) as OrgUser;

  return (
    <AppBar position='static'>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{
            height: '80px', 
            background: 'rgba(255, 255, 255, 1)',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Box sx={{width: '232px', pl: '24px'}}>
          <Button>
            <Typography component='h1' variant='h1'>
              DICE ID
            </Typography>
          </Button>
        </Box>
        <PopupState variant='popover'>
          {(popupState) => (
            <>
              <Box sx={{pr: 9.25}} {...bindToggle(popupState)}>
                { authUser?.name ? (
                  <Link
                    component={RouterLink}
                    to='#'
                    sx={{
                      color: theme.palette.common.black,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '0.938em',
                      fontWeight: '600',
                    }}
                    underline='hover'
                  >
                    <Avatar
                      sx={{width: 26, height: 26, mr: 1.5}}
                      alt='Avatar'
                      src={publicImage('avatar.jpg')}
                    />
                    {authUser.name}
                  </Link>
                ) : null}
              </Box>
              <Popper
                placement='bottom-start'
                sx={{
                  zIndex: 100,
                  '& .MuiPaper-root': {
                    borderRadius: '0 0 8px 8px',
                    width: 240,
                  },
                }}
                {...bindPopper(popupState)}
              >
                <ClickAwayListener onClickAway={popupState.close}>
                  <Paper sx={{boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'}}>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate('/login');
                          dispatch(setLogoutAndClearData());
                        }}
                        sx={{
                          py: '6px',
                          px: '17px',
                          color: theme.palette.primary.main,
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </PopupState>
      </Grid>
    </AppBar>
  );
}

export default MaintenanceHeader;
