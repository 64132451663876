import {axiosInstance} from './../../api/fetchData';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LOGGED_IN_TIMESTAMP, USER_PROFILE} from '../../constants/storage';
import {CustomUser} from '../../interface';
import {
  clearStorage,
  getNumber,
  getObject,
  setNumber,
  setObject,
  setString,
} from '../../utils/storageUtils';
import { clearMenuSlice } from './menuSlice';
import { clearOrganizationSlice } from './organizationSlice';
import { clearPermissionSlice } from './permissionSlice';
import { clearPlatformSlice } from './platformSlice';
import { ACCESS_TOKEN_EXPIRES_AT, REFRESH_TOKEN_EXPIRES_AT } from '../../constants';

// Define a type for the slice state
interface AuthState {
  user: CustomUser | null;
  loggedInTime: number | null;
  maintenance?: Maintenance;
}

interface Maintenance {
  status: boolean;
  result: any;
}

// Define the initial state using that type
const initialState: AuthState = {
  user: getObject<CustomUser>(USER_PROFILE, null),
  loggedInTime: getNumber(LOGGED_IN_TIMESTAMP, 0),
  maintenance: {
    status: false,
    result: {}
  }
};

export const setLogoutAndClearData = createAsyncThunk(
  'Auth/setLogoutAndClearData',
  async (args, {dispatch}) => {
    dispatch(setLogout());
    dispatch(clearMenuSlice());
    dispatch(clearOrganizationSlice());
    dispatch(clearPlatformSlice());
    dispatch(clearPermissionSlice());
    setString(ACCESS_TOKEN_EXPIRES_AT, '');
    setString(REFRESH_TOKEN_EXPIRES_AT, '');
    clearStorage();
    axiosInstance.defaults.headers.common['invoked_by'] = undefined;
    axiosInstance.defaults.headers.common['org_id'] = undefined;
    axiosInstance.defaults.headers.common['dept_id'] = undefined;
    window.tokenTimeout = undefined;
    window.history.go(0);
  },
);

export const authSlice = createSlice({
  name: 'Auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<{user: CustomUser}>) {
      const {user} = action.payload;
      state.user = user;
      setObject(USER_PROFILE, user);
      setNumber(LOGGED_IN_TIMESTAMP, new Date().getTime());
    },
    setLogout(state) {
      state.user = null;
      setObject(USER_PROFILE, null);
      setNumber(LOGGED_IN_TIMESTAMP, 0);
    },
    setMaintenance(state, res) {
      state.maintenance = {
        status: true,
        result: res.payload
      };
    },
    setNotInMaintenance(state) {
      state.maintenance = {
        status: false,
        result: {}
      }
    }
  },
});

export const {setLogin, setLogout, setMaintenance, setNotInMaintenance} = authSlice.actions;

export default authSlice.reducer;
