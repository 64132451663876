import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Grid, Link, Stack } from '@mui/material';
import MaintenanceIcon from '../../images/maintenance.png';
import MaintenanceHeader from './components/MaintenanceHeader';
import { Link as RouterLink } from 'react-router-dom';
import { selectIsInMaintenance } from '../../redux/selectors/authSlice';
import { useAppSelector } from '../../hook/useRedux';

function Maintenance() {
    const isInMaintenance = useAppSelector(selectIsInMaintenance);
    const [result, setResult] = useState<any>({});

    useEffect(() => {
        if (isInMaintenance?.status) {
            if (Object.keys(isInMaintenance?.result).length > 0) {
                setResult(isInMaintenance?.result.payload);
            }
        }
    }, [isInMaintenance]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                }}
            >
                <MaintenanceHeader />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 2,
                        p: 4,
                        pt: 4,
                        width: '100%'
                    }}
                >
                    <Typography variant="h3" component="h1" align="center" mt={2}>
                        System Maintenance In Progress
                    </Typography>
                    {result?.startTime
                        ? (
                            <Typography variant="h5" component="h5" sx={{ color: 'rgba(0, 74, 173, 1)' }} align="center" mt={1}>
                                {result.message + ". Please check back at " + result.endTime}
                            </Typography>
                        ) : (
                            <Typography variant="h5" component="h5" sx={{ color: 'rgba(0, 74, 173, 1)' }} align="center" mt={1}>
                                {" Sorry, We are undergoing maintenance. Please check back soon."}
                            </Typography>
                        )
                    }

                    <img src={MaintenanceIcon} style={{ borderRadius: 6 }} />
                    <Divider sx={{ mt: 2, border: '1px solid rgba(0, 0, 0, 0.12)', width: '80%' }} />
                    <Typography variant="h6" align="center" mt={1} mb={3}>
                        While waiting, you can check out these links
                    </Typography>
                    <Stack
                        sx={{ width: '50%' }}
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}
                    >
                        {/* <Link
                            component={RouterLink}
                            to='#'
                            sx={{ fontSize: '0.875em', fontWeight: 600 }}
                            onClick={() => {
                                window.open('http://google.com/', "_blank")
                            }}
                            underline='hover'
                        >
                            Update Notes
                        </Link> */}
                        <Link
                            component={RouterLink}
                            to='#'
                            sx={{ fontSize: '0.875em', fontWeight: 600 }}
                            onClick={() => {
                                window.open('https://dev.diceid.com/', "_blank")
                            }}
                            underline='hover'
                        >
                            Developer Documentation
                        </Link>
                        <Link
                            component={RouterLink}
                            to='#'
                            sx={{ fontSize: '0.875em', fontWeight: 600 }}
                            onClick={() => {
                                window.open('https://community.diceid.com/', "_blank")
                            }}
                            underline='hover'
                        >
                            Wipro DICE ID Community
                        </Link>
                        <Link
                            component={RouterLink}
                            to='#'
                            sx={{ fontSize: '0.875em', fontWeight: 600 }}
                            onClick={() => {
                                window.open('https://www.diceid.com/', "_blank")
                            }}
                            underline='hover'
                        >
                            Wipro DICE ID Homepage
                        </Link>
                    </Stack>
                </Box>
            </Box>
        </>
    );
}

export default Maintenance;
