import { createSelector } from 'reselect';
import { Role } from '../../interface';
import { RootState } from '../store';

export function selectPlatformState(state: RootState) {
  return state.platform;
}

export const selectPlatformRoles = createSelector(
  selectPlatformState,
  (platformState) => platformState.roles
);

export const selectPlatformRoleIds = createSelector(
  selectPlatformRoles,
  (roles) => roles.map((item) => item._id)
);

export const selectPlatformRoleDict = createSelector(
  selectPlatformRoles,
  (roles) => {
    return roles.reduce((dict, currentItem) => {
      dict[currentItem._id] = currentItem;
      return dict;
    }, {} as Record<string, Role>);
  }
);

export const selectPlatformRoleNameDict = createSelector(
  selectPlatformRoles,
  (roles) => {
    return roles.reduce((dict, currentItem) => {
      dict[currentItem.name] = currentItem;
      return dict;
    }, {} as Record<string, Role>);
  }
);
