import { RefreshTokenResponse } from './../interface/auth';
import { BehaviorSubject, catchError, from, map, Observable, Subscription, tap } from 'rxjs';
import { axiosWihtoutExpiresRequest } from './http';
import axios from 'axios';
import { setString } from '../utils/storageUtils';
import {
  ACCESS_TOKEN_EXPIRES_AT,
  REFRESH_TOKEN_EXPIRES_AT,
  SESSION_EXPIRED,
} from '../constants';
import isExpiresd from '../utils/isExpires';
import store from '../redux/store';
import { setLogoutAndClearData } from '../redux/features/authSlice';
import { getCodeChallengeAndVerifier } from '../utils/appUtils';
import {v4} from 'uuid';

export type InitLoginResponse = {
  result: string;
  status: string;
  iframeDoc?: string;
  statusCode?: string | number;
  flag?: string;
};

declare global {
  interface Window {
    getLoginInfo?: Promise<InitLoginResponse>;
    tokenTimeout?: NodeJS.Timeout;
    refreshTokenSubscription?: Subscription;
    refreshToken?: { originalAccessTokenExpireTime: string; refreshToken$: BehaviorSubject<RefreshTokenResponse>; };
  }
}

export function initLogin({
  redirect_uri,
}: {
  redirect_uri: string;
}): Observable<InitLoginResponse> {
  const {code_challenge} = getCodeChallengeAndVerifier();
  return from(
    axiosWihtoutExpiresRequest({
      baseURL: process.env.REACT_APP_LOGIN_API_BASE_URL,
      method: 'GET',
      url: '/login',
      params: {
        redirect_uri,
        code_challenge,
        state: v4()
      },
      withCredentials: true,
      headers: {
        org_id: undefined,
        invoked_by: undefined,
      },
    }) as Observable<InitLoginResponse>
  );
}

export function refreshToken() {
  return axiosWihtoutExpiresRequest({
    baseURL: process.env.REACT_APP_LOGIN_API_BASE_URL,
    method: 'POST',
    url: `/login/refresh-token`,
  }).pipe(
    tap((d: any) => {
      setString(ACCESS_TOKEN_EXPIRES_AT, d.result[ACCESS_TOKEN_EXPIRES_AT]);
      setString(REFRESH_TOKEN_EXPIRES_AT, d.result[REFRESH_TOKEN_EXPIRES_AT]);
      if (
        isExpiresd(d.result[ACCESS_TOKEN_EXPIRES_AT]) ||
        isExpiresd(d.result[REFRESH_TOKEN_EXPIRES_AT])
      ) {
        store.dispatch(setLogoutAndClearData());
        sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
      }
    }),
    catchError((err) => {
      store.dispatch(setLogoutAndClearData());
      sessionStorage.setItem(SESSION_EXPIRED, SESSION_EXPIRED);
      throw err;
    })
  ) as Observable<RefreshTokenResponse>;
}

export function fetchAuthorizeDocument(url: string) {
  return from(axios.get(url)).pipe(
    map((res) => res.data)
  ) as Observable<string>;
}
