import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DateRange, TimePeriod} from '../../interface';

// Define a type for the slice state
export interface ReportsDateRangeState {
  // better use this as lookup with LookupContext, than use redux selector
  dateRange?: DateRange;
  timePeriod?: TimePeriod;
}

// Define the initial state using that type
const initialState: ReportsDateRangeState = {
};

export const reportsDateRangeSlice = createSlice({
  name: 'ReportsDateRange',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearReportsDateRangeSlice() {
      return initialState;
    },
    setReportsDateRange(state, action: PayloadAction<ReportsDateRangeState>) {
      const {dateRange, timePeriod} = action.payload;
      if (dateRange) {
        state.dateRange = dateRange;
      }
      if (timePeriod) {
        state.timePeriod = timePeriod;
      }
    },
  },
});

export const {clearReportsDateRangeSlice, setReportsDateRange} =
  reportsDateRangeSlice.actions;

export default reportsDateRangeSlice.reducer;
