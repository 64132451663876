import { Buffer } from 'buffer';

export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  const part1 = arr[0] as string;
  const part2 = arr[1] as string;
  const mime = (part1.match(/:(.*?);/) as RegExpMatchArray)[1];
  const bstr = Buffer.from(part2, 'base64');
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; ++i) {
    u8arr[i] = bstr.readUint8(i);
  }

  return new File([u8arr], filename, { type: mime });
}
